@import '../../assets/scss/app';
@import '../../components/TableList/TableList.module.scss';

.company_header_container {
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3;
}

.companyList_header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  .left_header {
    gap: 12px;
  }
}

.company_icon {
  color: $primary-2-600 !important;
  height: 20px;
  margin-top: 2px;
}

.header_name {
  display: flex;
  padding-block: 5px;
}

.company_body_container {
  height: calc(100% - 76px);
  > div {
    height: 100%;
    display: grid;
  }

  .sub_title_name {
    padding-left: 0;
  }
}

.flex_end_container {
  display: flex;
  justify-content: flex-end;
}

.flex_between_container {
  width: 100%;
  justify-content: space-between;

  div,
  button {
    width: unset;
  }
}

.search_field {
  width: 95%;
  border: 1px solid $neutrals-300;
}

.paginate_container {
  padding-bottom: 25px;
}

.no_data_msg {
  font-weight: 500;
  font-size: $font-size-xl;
  color: $neutrals-900;
  margin-block: 50px;
  text-align: center;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
}

.company_list_container {
  display: grid;
  padding: 20px 20px 5px;
}
.company_list {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  display: grid;
  grid-row-start: auto;
  gap: 30px;
  grid-auto-rows: min-content;
}

.paginate_con_fixed {
  background-color: white;
  position: sticky;
  bottom: 0;
  z-index: 99;
  height: 45px;
  padding: 0 20px 10px;
}

@media only screen and (max-width: $max-desktop-lg-width) {
  .company_list_container {
    .company_list {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

@media only screen and (max-width: $max-tablet-lg-width) {
  .company_list_container {
    .company_list {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media only screen and (max-width: $min-tablet-lg-width) {
  .add_company_btn {
    width: 90px;
  }

  .sub_title_name {
    align-self: flex-start;
  }
}

@media only screen and (max-width: $max-mobile-width) {
  .loader {
    margin-top: 5vh;
  }

  .search_field {
    width: 100%;
  }

  .add_company_btn {
    width: min-content;

    > span {
      display: none;
    }
  }
}

@media only screen and (max-width: $min-mobile-width) {
  .add_company_btn {
    > span {
      display: none;
    }
  }

  .left_header {
    justify-content: space-between;
    width: 100%;
  }
  .company_list_container {
    .company_list {
      grid-template-columns: 1fr;
    }
  }
  .companyList_header {
    flex-direction: column;
  }

  .flex_end_container {
    width: 100%;
  }
}
