@import '../../assets/scss/app';

.mainContainer {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  font-weight: $semibold;

  .selectContainer {
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;

    .filterLabel {
      color: $neutrals-400;
      white-space: nowrap;
    }

    .itemLabelContainer {
      color: $neutrals-800;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 5px;
      justify-content: flex-end;

      .itemLabel {
        white-space: nowrap;
        max-width: 85%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.itemsContainer {
  position: absolute;
  max-width: 300px;
  width: max-content;
  z-index: 5;
  top: 35px;
  right: -10px;
  height: unset !important;

  .item {
    color: $neutrals-500;
    cursor: pointer;
    padding: 10px 15px;
    word-break: break-word;

    &:hover {
      background-color: $primary-50;
    }
  }

  .activeItem {
    background-color: $primary-50;
  }
}
@media only screen and (min-width: $min-tablet-lg-width) and (max-width: $max-tablet-lg-width) {
  .mainContainer {
    margin-left: unset;
  }
}

@media only screen and (max-width: $max-mobile-width) {
  .itemLabelContainer {
    max-width: 60%;
    .itemLabel {
      max-width: 100% !important;
    }
  }
}

@media only screen and (max-width: $min-mobile-width) {
  .mainContainer {
    width: fit-content;
  }
  .itemLabelContainer {
    max-width: 50%;
  }
}
