@import '../../../assets/scss/app';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-right: 35px;
  margin-block: 15px;

  .editBtnBig {
    padding-inline: 25px;
    gap: 15px;
    span,
    svg {
      font-weight: $bold;
    }
    svg {
      position: relative;
      top: -2px;
      font-size: 18px;
    }
  }

  .company_detail {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 25px;

    .company_logo_container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 130px;
      height: 65px;
      border: 1px solid $pale-aqua;

      .company_logo {
        max-height: 100%;
        max-width: 100%;
        min-height: 50px;
        min-width: 50px;
      }
    }

    .single_company_logo_container {
      width: 290px;
      height: 150px;
    }

    .company_info_container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      .company_info {
        display: flex;
        flex-direction: column;
        gap: 6px;
        word-break: break-word;

        .company_name {
          font-weight: 500;
        }

        .single_company_title {
          font-size: 28px;
        }

        .company_url {
          font-weight: 500;
          color: $neutrals-500;
        }
      }
    }
  }

  .btns_container {
    display: flex;
    gap: 20px;

    .edit_btn,
    .delete_btn {
      height: 30px;
      width: 30px;
      min-width: 30px !important;
      box-shadow: none;
      border-radius: unset !important;
    }
  }
}
@media only screen and (max-width: $max-mobile-width) {
  .company_info_container {
    flex-direction: column;
    gap: 10px;
    align-items: unset;
  }

  .edit_btn,
  .delete_btn {
    height: 25px !important;
    width: 50px !important;
  }
}

.company_card {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  gap: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  min-height: 212px;
  .logo_and_name {
    display: flex;
    gap: 13px;
    width: 100%;
    .company_logo {
      width: 40%;
      > img {
        width: 100%;
        border-radius: 50%;
      }
    }
    .content {
      width: 60%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      span {
        white-space: break-spaces;
        padding-bottom: 6px;
        min-height: 61px;
        a {
          text-decoration: none;
          color: unset;
          font-size: 20px;
        }
      }
      .location,
      .website {
        word-wrap: break-word;
      }
    }
  }

  .content_actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1;
    gap: 12px;
    > button {
      font-size: 13px !important;
      height: 30px;
      width: 60px;
    }
    .edit_btn,
    .delete_btn {
      margin-left: 10px;
      height: 30px;
      width: 30px;
      min-width: 30px !important;
      box-shadow: none;
      border-radius: unset !important;
    }
  }
}

// Drawer: For Company Details

.drawer {
  > div:nth-child(3) {
    width: 450px;
    padding: 20px;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .logoContainer {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 45px;
  }

  .websiteUrl {
    display: flex;
    justify-content: space-evenly;
    margin-block: 10px;
    .reset {
      > svg {
        font-size: 1rem;
      }
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 5px 10px;
      border-radius: 5px;
    }

    .reset {
      color: $primary-800;
      &:hover {
        background-color: $primary-50;
      }
    }
  }

  .companyDetailContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
    > div {
      display: flex;
      align-items: center;
      gap: 11px;
      .label {
        width: 30%;
      }
      > div {
        flex-grow: 1;
      }
      .field {
        display: flex;
        flex: 1;
      }
    }
  }
}
.form {
  display: contents;
}
.close {
  display: flex;
  color: $neutrals-400;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-color: $neutrals-100;
  }
}
.uploadImgCon {
  padding-top: unset;
}

.editOverlay {
  position: absolute;
  right: 28px;
  bottom: 19px;
  color: grey;
}
.btnCon {
  align-items: flex-end !important;
  flex: 1;
  width: 100% !important;
}
.btnUpload {
  border: none !important;
  position: relative;
  width: 208px;
  border-radius: 50%;
  min-height: unset !important;
  min-width: 18vw !important;
  > img {
    width: 100%;
    border-radius: 50%;
  }
}

@media only screen and (min-width: $min-tablet-width) and (max-width: $max-tablet-width) {
  .btnUpload {
    width: 200px !important;
  }
}

@media only screen and (max-width: 800px) {
  // Company List card
  .content {
    > span {
      white-space: nowrap;
      max-width: 95%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@media only screen and (max-width: $max-mobile-width) {
  .drawer {
    > div:nth-child(3) {
      width: 100%;
    }
  }

  .btnUpload {
    width: 200px !important;
  }
}
