@import '../../../assets/scss/app';

.action_buttons {
  width: 28px;
  height: 28px;
  min-width: 20px !important;
  border-radius: unset !important;
}

.delete_button {
  margin-right: 0 !important;
}

.action_icon {
  height: 14px;
}

.actionBtnsContainer {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 15px;
}

.disabled_btn {
  opacity: 0.46 !important;
}
