@import '../../../assets/scss/app';

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  border-radius: 15px !important;
  overflow-y: scroll;
  max-height: 95%;
  width: 50%;

  .label {
    color: $neutrals-500;
  }

  .setup_form_container {
    padding: 20px 30px;

    .title {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-left: 0;

      svg {
        color: $primary-2-800;
      }
    }

    .form_control_container {
      width: -webkit-fill-available;
      margin: 15px;

      .sub_text {
        padding-left: 0;
        padding-bottom: 10px;
      }

      .check_box {
        color: $primary-2-600;
      }

      .check_box_header {
        color: $neutrals-900;
        font-weight: $bold;
      }
    }
  }
}

@media only screen and (max-width: $max-tablet-width) {
  .container {
    width: 70%;
  }
}

@media only screen and (max-width: $max-mobile-width) {
  .container {
    width: 90%;
  }
}
