@import '../../../assets/scss/app';

.drawer {
  z-index: 9;
}

.drawer > div:nth-child(3) {
  padding-block: unset !important;
}

.resendMailButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  gap: 5px;

  > svg {
    font-size: 1rem;
  }
}

.form {
  padding-top: 20px;
}

.headerContainer {
  padding-block: 20px;
}

.headerContainer {
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header {
    display: flex;
    align-items: center;
  }
}

.form {
  padding-block: 0;
  > div {
    margin-bottom: 18px;
  }
}

.roleContainer {
  display: flex;
  align-items: center;
  gap: 15px;
}

.bottomFixed {
  position: sticky;
  bottom: 0;
  background-color: $white;
  z-index: 1;
  margin-bottom: 0px !important;
  padding-block: 20px;
  display: flex;
  gap: 20px;
  > button {
    flex: 1;
  }
}

.mailIcon {
  color: $primary-600;
}

.addRoleButton {
  background-color: $primary-600 !important;
  // making disable for product (temporarily)
  pointer-events: none;
  opacity: 0.4;
}

.checkBox {
  padding-left: 0 !important;
  color: $primary-600 !important;
}
