@import './color';
@import './spacing';
@import './text_display';

// Media query
// Mobile

$max-mobile-width: 600px;
$min-mobile-width: 450px;
// Tablet

$min-tablet-width: 601px;
$max-tablet-width: 1024px;

$min-tablet-lg-width: 800px;
$max-tablet-lg-width: 1024px;

// Desktop

$min-desktop-width: 1025px;
$max-desktop-width: 1440px;

$min-desktop-lg-width: 1441px;
$max-desktop-lg-width: 1600px;

.error_msg {
  color: $danger;
  position: absolute;
}

.horizontal_line {
  margin: 0;
  border: 0.4px solid $neutrals-300;
}

:export {
  maxMobile: $max-mobile-width;
  minMobile: $min-mobile-width;

  maxTablet: $max-tablet-width;
  minTablet: $min-tablet-width;

  maxTabletLg: $max-tablet-lg-width;
  minTabletLg: $min-tablet-lg-width;

  maxDesktop: $max-desktop-width;
  minDesktop: $min-desktop-width;

  maxDesktopLg: $max-desktop-lg-width;
  minDesktopLg: $min-desktop-lg-width;
}
