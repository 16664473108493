@import '../../../assets/scss/app';
@import '../Form/index.module.scss';

.displayAlignCenter {
  display: flex;
  align-items: center;
}

.imgField {
  border-left: none !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.prefixIcon {
  border-right: none !important;
  height: unset;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.imageUploadContainer {
  width: 100%;
}

.headerContainer {
  justify-content: flex-start;
}

.crop_button_container {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 10px;

  button {
    margin-left: unset !important;
  }
}

.label_content {
  font-size: 16px;
  color: $neutrals-900;
  font-weight: 500;
  margin-top: 2px;
}

@media only screen and (max-width: $min-tablet-lg-width) {
  .headerContainer {
    flex-direction: row;
  }
}

@media only screen and (max-width: $max-mobile-width) {
  .buttonContainer {
    flex-direction: row;
  }
}
