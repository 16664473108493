@import '../../assets/scss/app';
@import '../../assets/scss/_app.module.scss';
.headerContainer {
  justify-content: unset;
  > div {
    padding-block: 5px;
  }
}

.tab {
  display: flex;
  gap: 10px;

  .active_count,
  .inactive_count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 25px;
    color: $white;
    border-radius: 5px;
  }

  .active_count {
    background-color: $primary-2-600;
  }

  .inactive_count {
    background-color: $neutrals-300;
  }
}

.tabHeader {
  display: flex;
  align-items: center;
  margin: 0px 20px 15px 20px;
  border-bottom: 1px solid $neutrals-300;
  overflow-y: hidden;
  overflow-x: scroll;

  .selectedTab {
    opacity: 1;
    border-bottom: 3px solid $primary-2-600;
    position: relative;
    font-weight: 500;
  }
  & > span {
    opacity: 0.5;
    cursor: pointer;
    padding: 0 30px 10px;
  }
}

.tabHeader::-webkit-scrollbar {
  display: none;
}

.tabContainer {
  padding-inline: 20px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: auto;
}

.new_alert_indication_icon {
  color: $primary-2-600;
  width: 6px;
  margin-top: 4px;
}

.new_alert_row {
  background-color: rgba(25, 118, 210, 0.08);
}

.statusContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
  border-radius: 5px;
  color: $white;

  :nth-child(1) {
    font-size: 14px;
  }
}

.infoContainer {
  & > :first-child {
    height: 30px;
    width: 30px;
    justify-content: center;

    > svg {
      font-size: 16px;
    }
  }
}
