@import '../../../assets/scss/app';

.container {
  display: flex;
}

.input_container {
  height: 80%;
  display: block;
  outline: none;
  position: relative;
  margin-block: 0;
}

.phone {
  width: 95% !important;

  input {
    border: none !important;
    width: 100% !important;
    height: 36px !important;
  }

  div {
    background-color: $neutrals-300 !important;
    border-radius: 0px !important;
  }
}
