@import '../../assets/scss/button.module';
@import '../../assets/scss/app';

.page_header {
  padding: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.list_icon {
  color: $primary-2-600;
  margin-right: 20px;
  font-size: 18px;
}

.page_name {
  font-size: 20px;
  color: $neutrals-900;
}

.field_name {
  font-size: 16px;
  color: $neutrals-900;
}

.gridTitleContainer {
  white-space: pre-wrap;
}

.error {
  position: absolute;
}

.buttons_container {
  flex-direction: row;
}

.input_field {
  width: 100%;
  height: 36px;
  border: 1px solid $neutrals-300;
  border-radius: 2px;
}

.grid_textField {
  height: 40px;
}

.crop_button_container {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 10px;

  button {
    margin-left: unset !important;
  }
}
.check_box {
  padding: 0px !important;
  margin-right: 10px !important;
}

.imageUploadContainer {
  width: 100%;
}

.label_content {
  font-size: 16px;
  color: $neutrals-900;
  font-weight: 500;
  margin-top: 2px;
}

.text_field {
  box-sizing: border-box;
  padding-left: 16px;
  height: 38px !important;
  background-color: $white;
  border: 1px solid $neutrals-300;
  outline: none;
  display: flex;
  font-weight: 400;
  font-size: 16px;
  color: $black;
  width: 100%;
  border-radius: 2px;
}

@media only screen and (max-width: $min-tablet-lg-width) {
  .page_header {
    flex-direction: row;
  }
}
