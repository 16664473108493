@import '../../assets/scss/app';

.container {
  display: grid;
  place-content: center;
  padding: 10px;
}
.content_container {
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  padding-top: 10px;
  padding-bottom: 10px;
}
.page_loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

:export {
  primaryColor: $primary-300;
}
