@import '../../../assets/scss/app';

.papper {
  & ul {
    padding-block: 2px;
  }
}

.container {
  width: 100%;
  margin: 0 !important;

  .dropdown {
    border: none !important;
    border-radius: 2px !important;
    background-color: $white !important;

    .dropdownPlaceholder {
      display: flex;
      justify-content: center;
      gap: 5px;
    }

    .placeholder {
      color: $white;
    }

    .menu {
      padding: 0;
      ul {
        padding: 0 !important;
      }
    }

    div {
      display: inline-block;
      align-items: center;
      background-color: $white;
      color: $black;
      border: solid 1px $neutrals-300;
      padding: 8px 20px 8px 10px !important;
      border-radius: 2px;
      height: 20px !important;
      min-height: 1em;
      color: $neutrals-500;
      font-weight: 500;
    }

    div:disabled {
      background-color: #00000091;
    }

    &::after,
    &::before,
    & div::after,
    & div::before {
      border-bottom: none !important;
    }
  }

  .label_blue {
    padding-top: 10px !important;
  }

  .label_pink {
    padding-top: 10px !important;
  }
}

.disable {
  div {
    background-color: $neutrals-100 !important;
    color: $white !important;
  }
}

.option {
  margin: 10px;
  cursor: pointer;
}

.text_container {
  position: relative;
  width: 100%;
}

.required {
  position: absolute;
  right: 8px;
  top: 20px;
  z-index: 1;
  color: $danger;
}

.chip {
  height: 30px !important;
  margin-right: 2px !important;
  border-radius: 16px !important;
  padding: 0px !important;
  display: flex !important;
}

.icon {
  display: flex;

  svg {
    width: 20px;
    vertical-align: middle;
  }
}

.dropdownContainer {
  color: $neutrals-600 !important;

  & > span:first-child {
    color: $neutrals-500;
    font-weight: 500;
  }
}

.dropdownItem:first-child:focus {
  background-color: unset !important;
}

.dropdownItem:first-child:hover {
  background-color: $primary-50 !important;
}

.deleteContainer {
  color: $danger !important;
}

.deleteContainer:hover {
  background-color: #ffe8e6 !important;
}

.editContainer {
  color: $secondary-700 !important;
}

.editContainer:hover {
  background-color: $secondary-100 !important;
}

@media only screen and (max-width: $max-mobile-width) {
  .container {
    .dropdown {
      margin-block: 0px !important;
    }
  }
}
