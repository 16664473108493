@import './color';
@import './spacing';
@import './text_display';
@import '../../components/Form/TextField/textField.module.scss';

* {
  font-family: 'Montserrat' !important;
  font-style: normal !important;
}
*::-webkit-scrollbar {
  display: none;
}

.header_container {
  display: flex;
  padding: 20px;
  font-weight: $semibold;
  color: $neutrals-900;
  justify-content: space-between;
  > div {
    display: flex;
    > div {
      align-items: center;
    }
  }
}

.paperScrollable {
  height: 100% !important;
  overflow: auto !important;
}

.solid_list_icon {
  color: $primary-2-600 !important;
  height: 20px !important;
  width: 20px !important;
}
.solid_list_icon_imp {
  color: $primary-2-600 !important;
  height: 20px !important;
}

.icon_container {
  background-color: $neutrals-300;
  border-radius: 0px 1px 1px 0px;
  width: 49px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $neutrals-300;
  border-right: none;
}

.TextField {
  box-sizing: border-box;
  height: 38px !important;
  border: 1px solid $neutrals-300;
  border-radius: 2px;
}

.TextArea {
  box-sizing: border-box;
  min-height: 44px !important;
  border: 1px solid $neutrals-300;
  border-radius: 2px;
}

.TextArea::-webkit-scrollbar {
  display: unset;
}

.subheader_container {
  display: flex;
  justify-content: space-between;
}

.card_company_details {
  padding: 20px;
}

.title_name_icon {
  color: $orange;
  width: 42px !important;
  height: 37px !important;
}
.icon_image {
  height: 23px;
  width: 23px;
}

.title_name_container {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.title_name {
  line-height: 25px;
  margin-block: 0px;
  padding-left: $spacing-04;
  font-weight: $semibold;
  color: $neutrals-900;
  white-space: nowrap;
}
.name_details {
  display: flex;
  font-weight: $regular;
  color: $neutrals-600;
  padding-left: 20px;
  margin-top: 15px;
}

.form_style {
  display: flex;
  flex-direction: column;
  height: calc(100% - 64px);
  overflow: auto;
}

.grid_container {
  // padding: 20px 2px 20px 30px;
  padding: 20px;
}

.field_styles_con {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: $spacing-02;
}

.grid_title_name {
  font-size: 16px;
  color: $neutrals-900;
  display: flex;
  justify-content: flex-start;
  align-self: center;
  white-space: nowrap;
  color: $neutrals-900;
  font-weight: 500;
}

.image_icon {
  width: 30px;
  height: 24px;
  margin-right: $spacing-04;
}

.upload_image_container {
  display: flex;
  justify-content: center;
  padding-top: $spacing-05;
}

.status_container {
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;

  svg {
    height: 15px;
    width: 15px;
  }
}

.green_status_icon {
  color: $success;
}

.red_status_icon {
  color: $danger;
}

.yellow_status_icon {
  color: $warning;
}

.orange_status_icon {
  color: $orange;
}

.green_status_background {
  background-color: $success;
}

.red_status_background {
  background-color: $danger;
}

.yellow_status_background {
  background-color: $warning;
}

.header_icon {
  padding-right: 15px;
  color: $primary-2-600;
}

.emptyContainer {
  display: flex;
  flex-direction: column;
  min-height: 180px;
  align-items: center;
  justify-content: center;
  font-size: $font-size-2xl;
  font-weight: $medium;
  gap: 70px;
}

.flexHeightAuto {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.h_100 {
  height: 100% !important;
}

// toaster Container

.toastContainer > :first-child {
  > :first-child {
    width: max-content;
    min-width: 320px;
    left: unset;
  }
  > :nth-child(1) {
    text-transform: capitalize;
  }
}

// add listing page styles

.outerContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 60%;
}

.keyContainer {
  align-items: flex-start;
  flex-grow: 0;
  flex-basis: 30%;
  white-space: inherit;
}

.valueContainer {
  flex: 1 1 100%;
}

.gridContainer {
  align-items: center;
}

.drawer > div:nth-child(3) {
  width: 450px;
  padding-inline: 20px;
  padding-top: 20px;
}

@media only screen and (max-width: $max-tablet-lg-width) {
  .feedback_header_container {
    flex-direction: column;
    align-items: flex-start;
  }

  .outerContainer {
    width: 90%;
  }
}
@media only screen and (max-width: $min-tablet-lg-width) {
  .header_container {
    flex-direction: column;
    gap: 12px;
  }

  .feedback_header_container {
    gap: 0px !important;
  }
  .keyContainer {
    align-self: flex-start;
  }
}

@media only screen and (max-width: $max-mobile-width) {
  .keyContainer {
    width: 100%;
  }

  .title_name {
    padding-left: 8px;
  }
  .header_container {
    flex-direction: column;
    justify-content: center;
  }
  .name_details {
    font-size: $font-size-lg;
  }

  .buttons_container {
    display: flex;
    justify-content: center;
    padding-top: $spacing-04;
  }

  .field_styles_con {
    flex-direction: column;
    align-items: unset;
    margin-top: $spacing-02;
    gap: 8px;
  }

  .grid_container {
    padding-left: $spacing-06;
    padding-right: $spacing-06;
  }

  .grid_title_name {
    justify-content: unset;
    padding-right: unset;
  }

  .subheader_container {
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
  }

  .outerContainer {
    width: 100%;
    gap: 1.5rem;
  }

  .valueContainer {
    width: 100%;
  }

  .gridContainer {
    align-items: unset;
  }

  .drawer > div:nth-child(3) {
    width: 100%;
  }
}
@media only screen and (max-width: $min-mobile-width) {
  .header_container {
    flex-direction: row;
  }
  .feedback_header_container {
    padding-bottom: 20px;
    gap: 0px;
  }
}
