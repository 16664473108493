@import '../../assets/scss/app';
@import '../../assets/scss/_app.module.scss';
@import '../../assets/scss/_button.module.scss';
@import '../../assets/scss/_form_field.module.scss';

.gridContainer {
  padding: 20px;

  .gridLabel {
    justify-content: unset;
  }
}

.accessibilityGroupContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 40px;
  justify-content: space-evenly;

  .groupHeader {
    color: $neutrals-900;
    margin-bottom: 10px;
  }

  .formControlContainer {
    display: flex;
    gap: 20px;

    .label {
      color: $neutrals-500;

      .checkBox {
        color: $primary-2-600;
      }
    }
  }
}
.disable {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  background-color: transparent;
  opacity: 0.5;
}

.headerFixed,
.bottomFixed {
  position: sticky;
  z-index: 2;
  background-color: $white;
}

.headerFixed {
  top: 0;
}

.bottomFixed {
  bottom: 0;
}

.table_container {
  padding: 0 20px 10px;
}

@media screen and (max-width: $max-mobile-width) {
  .gridContainer {
    flex-direction: column;
  }
  .groupContainer {
    width: 100%;
  }
}
