@import '../../assets/scss/app';

.drawerRoot {
  z-index: 5 !important;
}

.card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  border-radius: 15px !important;
  overflow-y: scroll;
  max-height: 95%;
  width: 50%;

  .header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $white;

    > div {
      gap: 10px;
      padding-block: 18px;
      justify-content: flex-start;

      svg {
        color: $primary-2-600;
        align-self: center;
      }
    }
  }
}
.addLinkStyle {
  width: 45%;
}
.addInsuranceStyle {
  width: 60%;
}

.maintenance {
  width: 65%;
}

.changePassword {
  width: 40%;
}

.card::-webkit-scrollbar {
  display: none;
}

.grid_container {
  width: 100% !important;
  margin-left: 0 !important;
  grid-row-gap: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 21px;
}

.grid_field_name_container {
  padding-left: unset !important;
  align-self: center;

  .grid_field_name {
    width: 100%;
    white-space: break-spaces;
    text-align: start;
  }
}

.grid_textField {
  width: 100% !important;
}

.passwordField {
  > div,
  input {
    box-sizing: border-box;
    height: 38px !important;
    border: 1px solid $neutrals-300;
    border-radius: 2px;
    outline: none;
    border: inherit;
    padding-block: unset;
    color: $neutrals-500;
    padding-inline-end: 20px;

    fieldset {
      border: 1px solid $neutrals-300 !important;
    }
  }
}

.iconlessPasswordField {
  > div,
  input {
    padding-inline-end: 5px;
  }
}

.buttons_container {
  display: flex;
  justify-content: space-between;

  > div {
    padding: 20px;
  }
}
.btn {
  width: 100%;
  height: 38px;
  background-color: $primary-2-600 !important;
  font-size: inherit !important;
}

.disabled_btn {
  background-color: $neutrals-300 !important;
  color: $white !important;
}

.disabled_text_field,
.disabled_datepicker {
  background-color: $neutrals-100;
}

.suffixTextClass {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary-50;
  color: $primary-900;
  border: 1px solid $neutrals-300;
  border-left: unset;
  padding-inline: 10px;
  font-weight: 500;
}

.text_area {
  width: -webkit-fill-available;
  max-width: -webkit-fill-available;
  border: 1px solid $neutrals-300;
  outline: none;
  padding: 10px;
  font-size: inherit;
  color: $neutrals-500;
  font-weight: 500;
  white-space: pre-line;
}

.attachment_container {
  width: 100%;
  padding-block: 10px;

  .attachment_title {
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
      color: $primary-2-600;
    }

    span {
      padding: 0;
    }
  }
}

.checkBox > svg {
  color: $primary-2-800;
}

.radio_btn > span:first-child {
  color: $primary-2-800 !important;
}

.icon {
  border-radius: 2px 0px 0px 2px;
  height: 36px;
}

.eye {
  cursor: pointer;
  color: $neutrals-500;
  opacity: 50%;
  position: absolute;
  right: 5%;
  z-index: 2;
}

@media screen and (max-width: $max-tablet-width) {
  .addInsuranceStyle {
    width: 75%;
  }
  .card,
  .addLinkStyle {
    width: 70%;
  }
  .maintenance {
    width: 80%;
  }

  .changePassword {
    width: 50%;
  }
}

@media screen and (max-width: $min-tablet-lg-width) {
  .card {
    width: 85%;
    .header {
      > div {
        flex-direction: row !important;
        justify-content: start;
      }
    }
  }

  .changePassword {
    width: 65%;
  }
}

@media screen and (max-width: $max-mobile-width) {
  .changePassword {
    width: 90%;
  }

  .grid_field_name {
    text-align: unset !important;
  }

  .card {
    .header {
      div {
        justify-content: unset;
        flex-direction: row;
      }
    }
  }
}

@media only screen and(min-width:$min-tablet-width) and (max-width: $max-tablet-width) {
  .eye {
    width: auto;
  }
}
