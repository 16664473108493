/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

html {
  scroll-behavior: smooth;
}

:root {
  --primary: #023b96;
  --secondary: #03c988;
  --light-blue: #00c3d6;
  --white: #fff;
}

a {
  color: var(--primary);
  text-decoration: none;
}

a:hover {
  color: var(--secondary);
  text-decoration: none;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: var(--primary);
}

#preloader:before {
  content: '';
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid var(--primary);
  border-top-color: var(--white);
  border-bottom-color: var(--white);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: var(--primary);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top svg {
  font-size: 22px;
  color: var(--white);
}

.back-to-top:hover {
  background: var(--secondary);
  color: var(--white);
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 10px 0;
}

#header.header-scrolled,
#header.header-inner-pages {
  background: var(--primary);
}

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#header .logo a {
  color: var(--white);
}

#header .logo img {
  max-height: 40px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  font-weight: 500;
  color: var(--white);
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: var(--secondary);
}

.navbar .login,
.navbar .login:focus {
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 50px;
  color: var(--white);
  font-size: 14px;
  border: 2px solid var(--secondary);
  font-weight: 600;
  text-decoration: none;
}

.navbar .login:hover,
.navbar .login:focus:hover {
  color: var(--white);
  background: var(--secondary);
}

/**
* Mobile Navigation
*/
.mobile-nav-toggle {
  color: var(--white);
  font-size: 28px;
  cursor: pointer;
  display: none !important;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: var(--white);
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block !important;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(40, 58, 90, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: var(--white);
  overflow-y: auto;
  transition: 0.3s;
  width: 300px;
  height: fit-content;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: var(--primary);
  justify-content: center;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: var(--primary);
}

.navbar-mobile .login,
.navbar-mobile .login:focus {
  margin: 15px;
  color: var(--primary);
}

@media (max-width: 991px) {
  .login-link {
    display: flex;
    justify-content: center;
  }

  .login {
    width: 50%;
  }
}

@media (max-width: 575px) {
  .navbar-mobile ul {
    width: unset;
    left: 15px;
  }
}

/*--------------------------------------------------------------
# Home Section
--------------------------------------------------------------*/
#home {
  width: 100%;
  height: 80vh;
  background: linear-gradient(180deg, #13005a 0%, var(--secondary) 56.77%);
}

#home .container {
  padding-top: 72px;
}

#home h1 {
  margin-bottom: 20px;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: var(--white);
}

#home p {
  color: var(--white);
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 24px;
}

#home h3 {
  color: var(--white);
  margin-bottom: 15px;
  font-size: 16px;
  text-transform: uppercase;
}

#home .btn-get-started {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px 11px 28px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px 0 0 0;
  color: var(--white);
  background: var(--primary);
  text-decoration: none;
}

#home .btn-get-started:hover {
  background: #13005a;
}

#home .btn-watch-video {
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: 0.5s;
  margin: 10px 0 0 25px;
  color: var(--white);
  line-height: 1;
}

#home .btn-watch-video i {
  line-height: 0;
  color: var(--white);
  font-size: 32px;
  transition: 0.3s;
  margin-right: 8px;
}

#home .btn-watch-video:hover i {
  color: var(--secondary);
}

#home .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (max-width: 991px) {
  #home {
    height: 100vh;
    text-align: center;
  }

  #home .animated {
    animation: none;
  }

  #home .hero-img {
    text-align: center;
  }

  #home .hero-img img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  #home h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #home h2 {
    line-height: unset;
    margin-bottom: unset;
  }

  #home .hero-img img {
    width: 70%;
  }
}

@media (max-width: 575px) {
  #home .hero-img img {
    width: 80%;
  }

  #home .btn-get-started {
    font-size: 16px;
    padding: 10px 24px 11px 24px;
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding-block: 60px 0px;
  overflow: hidden;
}

.section-bg {
  background-color: #f3f5fa;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: var(--primary);
}

.section-title h2::before {
  content: '';
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: '';
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: var(--primary);
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  section {
    padding-block: 60px 20px;
    overflow: hidden;
  }
}

/*--------------------------------------------------------------
# features
--------------------------------------------------------------*/
.features h1 {
  color: var(--primary);
}

.features h6 {
  color: var(--light-blue);
}

.features .img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.features span {
  font-size: 14px;
}

.features .container .content-container {
  padding-block: 16px 27px;
}

.features .container .list-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.features .container .list-container span {
  padding-block: 5px;
  display: flex;
  align-items: center;
}

.features .container .list-icon {
  width: 25px;
  padding-right: 8px;
}

.features button {
  background: var(--primary);
  border: 0;
  padding: 12px 34px;
  color: var(--white);
  transition: 0.4s;
  border-radius: 50px;
}

.features button:hover {
  background: var(--secondary);
}

@media (max-width: 1024px) {
  .features .content,
  .features .accordion-list {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 991px) {
  .features .img {
    min-height: 400px;
    margin-bottom: 30px;
  }

  .features .content {
    padding-top: 30px;
  }

  .features .accordion-list {
    padding-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .features .img {
    min-height: 200px;
    margin-bottom: 25px;
  }

  .features .container .content-container {
    padding-bottom: 0;
  }
}

/*--------------------------------------------------------------
# Skills
--------------------------------------------------------------*/
.skills h1 {
  color: var(--primary);
}

.skills .content h3 {
  font-weight: 700;
  font-size: 32px;
  color: var(--primary);
}

.skills .content h6 {
  color: var(--light-blue);
}

.skills .content p {
  font-size: 14px;
}

.skills .content button {
  background: var(--primary);
  border: 0;
  padding: 12px 34px;
  color: var(--white);
  transition: 0.4s;
  border-radius: 50px;
}

.skills .content button:hover {
  background-color: var(--secondary);
}

/*--------------------------------------------------------------
# Why Assetolio
--------------------------------------------------------------*/
.why-assetolio p {
  font-size: 14px;
}

.why-assetolio .icon-box {
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  padding: 50px 30px;
  transition: all ease-in-out 0.4s;
  background: var(--white);
}

.why-assetolio .icon-box .icon {
  margin-bottom: 10px;
  height: 50px;
}

.why-assetolio .icon-box .icon img {
  height: 100%;
  font-size: 36px;
  transition: 0.3s;
}

.why-assetolio .icon-box h4 {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 24px;
}

.why-assetolio .icon-box h4 a {
  color: var(--primary);
  transition: ease-in-out 0.3s;
  text-decoration: none;
}

.why-assetolio .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.why-assetolio .icon-box:hover {
  transform: translateY(-10px);
}

.why-assetolio .icon-box:hover h4 a {
  color: var(--primary);
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
  background: linear-gradient(90deg, #13005a 0%, var(--secondary) 56.77%);
  background-size: cover;
  padding-block: 80px;
  margin-top: 30px;
}

.cta h3 {
  color: var(--white);
  font-size: 28px;
  font-weight: 700;
}

.cta p {
  color: var(--white);
}

.cta .cta-btn {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid var(--white);
  color: var(--white);
  text-decoration: none;
}

.cta .cta-btn:hover {
  background-color: #13005a;
  border: 2px solid #13005a;
}

@media (max-width: 1024px) {
  .cta {
    background-attachment: scroll;
  }
}

@media (min-width: 769px) {
  .cta .cta-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .faq-list {
  padding: 0 100px;
}

.faq .faq-list ul {
  padding: 0;
  list-style: none;
}

.faq .faq-list li + li {
  margin-top: 15px;
}

.faq .faq-list li {
  padding: 20px;
  background: var(--white);
  border-radius: 4px;
  position: relative;
}

.faq .faq-list a {
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
}

.faq .faq-list .icon-help {
  font-size: 24px;
  position: absolute;
  right: 0;
  left: 20px;
  color: var(--primary);
}

.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}

.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}

.faq .faq-list .icon-show {
  display: none;
}

.faq .faq-list a.collapsed {
  color: var(--primary);
  transition: 0.3s;
}

.faq .faq-list a.collapsed:hover {
  color: var(--primary);
}

.faq .faq-list a.collapsed .icon-show {
  display: inline-block;
}

.faq .faq-list a.collapsed .icon-close {
  display: none;
}

@media (max-width: 1200px) {
  .faq .faq-list {
    padding: 0;
  }
}

/*--------------------------------------------------------------
# Our focus
--------------------------------------------------------------*/

#our-focus h6 {
  color: var(--light-blue);
  text-transform: uppercase;
}

#our-focus p {
  font-size: 14px;
}

.our-focus-content {
  padding: 0px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/

.contact .form {
  width: 100%;
  border-top: 3px solid var(--primary);
  border-bottom: 3px solid var(--primary);
  padding: 30px;
  background: var(--white);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact .form .form-group {
  padding-bottom: 8px;
  margin-bottom: 20px;
}

.contact .form label {
  padding-bottom: 8px;
}

.contact .form input,
.contact .form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .form input:focus,
.contact .form textarea:focus {
  border-color: var(--primary);
}

.contact .form input {
  height: 44px;
}

.contact .form textarea {
  padding: 10px 12px;
}

.contact .form button[type='submit'] {
  background: var(--primary);
  border: 0;
  padding: 12px 34px;
  color: var(--white);
  transition: 0.4s;
  border-radius: 50px;
}

.contact .form button[type='submit']:hover {
  background: var(--secondary);
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  font-size: 14px;
  background: var(--primary);
}

#footer .logo {
  max-height: 40px;
  margin-bottom: 15px;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  background: var(--white);
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 28px;
  margin: 0 0 10px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  text-transform: capitalize;
  font-weight: 600;
  color: var(--primary);
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: var(--primary);
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul svg {
  padding-right: 2px;
  color: var(--primary);
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: var(--primary);
}

#footer .footer-top .social-links a {
  display: inline-block;
  color: var(--primary);
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  transition: 0.3s;
}

#footer .footer-top .social-links a svg {
  font-size: 30px;
}

#footer .footer-top .social-links a:hover {
  color: var(--secondary);
  text-decoration: none;
}

#footer .footer-bottom {
  padding-top: 30px;
  padding-bottom: 30px;
  color: var(--white);
}

@media (max-width: 768px) {
  #footer .footer-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  #footer .copyright {
    text-align: center;
    float: none;
  }
}
