@import '../../assets/scss/app';

.card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  border-radius: 15px !important;
  min-width: 40%;
  overflow-y: scroll;
  max-height: 95%;

  .header_container {
    position: sticky;
    top: 0;
    background-color: $white;
  }

  .header {
    justify-content: space-between;

    .header_icon {
      display: flex;
      align-items: center;
      gap: 10px;

      .destroyContainer {
        color: $danger;
        border: 1px solid $danger;
      }

      .editContainer {
        color: $secondary-800;
        border: 1px solid $secondary-800;
      }

      .destroyContainer,
      .editContainer {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        border-radius: 5px;
        padding-inline: 5px;
        height: 100%;
        &:hover {
          scale: 1.05;
        }

        .editIcon,
        .destroyIcon {
          font-size: 16px;
        }
      }
    }

    .close_icon {
      color: $neutrals-400;
      cursor: pointer;
    }
  }

  .tabHeader {
    display: flex;
    align-items: center;
    margin-inline: 20px;
    border-bottom: 1px solid $neutrals-300;
    overflow-y: hidden;
    overflow-x: scroll;

    .selectedTab {
      opacity: 1;
      border-bottom: 3px solid $primary-2-600;
      position: relative;
      font-weight: 500;
    }

    span {
      opacity: 0.5;
      cursor: pointer;
      padding-inline: 30px;
      padding-block: 10px 20px;
    }
  }

  .tabHeader::-webkit-scrollbar {
    display: none;
  }

  .title {
    padding-left: 0px;
  }

  .more_details_btn_container {
    display: flex;
    justify-content: flex-end;
    padding-inline: 20px;
    padding-bottom: 20px;

    .more_details_btn {
      width: auto;
      font-size: 16px !important;
    }
  }
}

.card::-webkit-scrollbar {
  display: none;
}

.body_container {
  padding: 20px;
}

.table_container {
  overflow-x: scroll;

  .asset_details_table {
    flex-basis: 70%;
    height: fit-content;
    border-bottom: none;
    border-spacing: unset;
    width: -webkit-fill-available;
    overflow: scroll;

    .asset_detail_row {
      display: flex;

      .asset_key,
      .asset_value {
        display: flex;
        align-items: center;
        padding-block: 11px;
        color: $neutrals-800;
        font-size: 16px;
        font-weight: 500;
        white-space: pre-line;
      }

      .asset_key {
        flex-basis: 35%;
        color: $neutrals-500;
      }

      .asset_value {
        flex-basis: 70%;
        color: $neutrals-800;
        word-break: break-all;
      }

      .active_status {
        background-color: $success;
      }

      .repair_status {
        background-color: $danger;
      }
    }
  }
}

.table_container::-webkit-scrollbar {
  display: none;
}

.attachment_container {
  width: 100%;
  padding-top: 30px;

  .attachment_title {
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
      color: $primary-2-600;
    }
    span {
      padding: unset;
    }
  }
  .attachments {
    padding-top: 20px;
  }
}

@media screen and (max-width: $max-tablet-width) {
  .card {
    width: 60%;
  }
}

@media screen and (max-width: $min-tablet-lg-width) {
  .card {
    width: 70%;
  }
}

@media screen and (max-width: $min-mobile-width) {
  .card {
    min-width: 90%;

    .asset_key,
    .asset_value {
      padding-block: 10px !important;
      padding-inline: 12px !important;
    }
  }
}
