@import '../../assets/scss/app';

.actionButtonsContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.accept,
.reject,
.info {
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 6px 10px;
  svg {
    font-size: 18px;
  }
}

.accept {
  background-color: $secondary-500;
  span,
  svg {
    transition: scale 100ms ease;
  }
  &:hover {
    span,
    svg {
      scale: 1.13;
    }
  }
}

.accepted {
  cursor: default;
}

.reject {
  background-color: $pale-red;
  span,
  svg {
    transition: scale 100ms ease;
  }
  &:hover {
    span,
    svg {
      scale: 1.13;
    }
  }
}

.rejected {
  cursor: default;
}

.info {
  background-color: $info;
  span,
  svg {
    transition: scale 100ms ease;
  }
  &:hover {
    span,
    svg {
      scale: 1.13;
    }
  }
}

.statusContainer {
  display: flex;
  align-items: center;
}

.tulipBtnStyle {
  padding-inline: 14.1px;
}
.rejectedStyle {
  padding-inline: 12.7px;
}
