@import '../../../assets/scss/app';

.add_button {
  background-color: $primary-600 !important;
  border-radius: 5px;
  color: $white !important;
  margin-top: 3px;
  gap:12px
}
.createButtonRoot {
  svg {
    transition: scale 100ms ease;
    font-size: 16px;
  }
}
.createButtonRoot:hover {
  span {
    scale: 1.15;
  }
  svg {
    scale: 1.19;
  }
}

.addBtnText{
  font-size: 15px;
}


@media only screen and (max-width: $max-mobile-width) {
  .add_button {
    min-height: 35px;
    align-items: center;
    gap:5px
  }

  .plusIconText{
    display: none;
  }

}
