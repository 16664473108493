@import '../../assets/scss/app';

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #e3f3f1 url('../../assets/images/AssetolioLogo.svg') no-repeat right bottom / auto 65%;

  .headerGradiant {
    width: 50%;
    margin-left: auto;
    height: 50px;
    background: linear-gradient(90deg, #0f2a8c 35%, #03c988 65%);
  }

  .logoContainer {
    margin-bottom: 30px;
    display: flex;
    height: 55px;

    .logo {
      height: 100%;
      padding-left: 80px;
    }
  }

  .body {
    flex: 1;
  }
}

.form {
  padding: 20px 30px;
  border-radius: 20px !important;
  width: 450px;
  margin-left: 20%;

  .formHeader {
    color: #03367e;
    font-size: 28px;
    font-weight: $bold;
    margin-bottom: 20px;

    > span {
      border-bottom: 2px solid #03367e;
      padding-bottom: 5px;
    }
  }

  .inputContainer {
    .fieldContainer {
      position: relative;
      padding-bottom: 20px;
    }

    .label {
      color: #03367e;
      font-weight: 400;
      font-size: 16px;
    }

    .field {
      width: 100%;
      display: flex;
      align-items: center;
      border: 2px solid #1694c0;
      border-radius: 10px;

      .iconContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #1694c0;
        padding-block: 8px;
        padding-inline: 10px 12px;
        border-radius: 7px;

        .icon {
          color: $white;
        }
      }

      .fieldIcon {
        cursor: pointer;
        color: #cacaca;
        margin-right: 10px;
      }

      > input {
        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0px 1000px white inset !important;
        }
        border: none;
        width: 100%;
        padding-inline: 10px;
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
  .forgotPasswordContainer {
    > span {
      color: #1694c0;
      cursor: pointer;
      &:hover {
        color: #03367e;
      }
    }
    text-align: right;
    color: #1a7fc1;
  }
  .button {
    display: flex;
    justify-content: center;
    font-size: 18px;
    margin-block: 20px;
    border-radius: 10px;
    padding-block: 10px;
    width: 100%;
    background-color: #03c988;
    &:hover {
      background-color: #00af75;
    }
  }
  .separator {
    margin-inline: auto;
    position: relative;
    color: #03367e;
    width: 75%;

    > div > hr {
      border: 1px solid;
    }

    .dividerText {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $white;
      padding-inline: 10px;
    }
  }
  .googleLogoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    > span {
      cursor: pointer;
      height: 55px;
      padding: 10px;
      border-radius: 50%;
      &:hover {
        background-color: $primary-50;
        animation: 0.2s;
      }
      > img {
        height: 100%;
      }
    }
  }
}

.circularProgress {
  > span {
    height: 20px;
    width: 20px;
    color: $white;
    display: flex;
    margin-right: 10px;
  }
}

@media only screen and (max-width: $max-tablet-lg-width) {
  .container {
    background-size: auto 40%;

    .headerGradiant {
      width: 100%;
    }

    .logoContainer {
      height: 180px;
      justify-content: center;
      margin-block: 30px;
      .logo {
        padding-left: 0;
      }
    }
  }

  .form {
    margin-inline: auto;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: $min-tablet-width) {
  .container {
    background-image: none;

    .headerGradiant {
      height: 30px;
    }
  }

  .form {
    max-width: 90%;
    min-width: 70%;
    padding: 10px 20px;

    .formHeader {
      font-size: 22px;
    }

    .inputContainer {
      .fieldContainer {
        padding-bottom: 10px;
      }
      .label {
        font-size: 14px;
      }
      .field {
        width: unset;
      }
      .button {
        font-size: 16px;
        padding-block: 10px;
        margin-block: 10px;
      }
    }
  }
}
