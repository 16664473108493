@import '../../assets/scss/app';

.attachment_container {
  min-width: 400px;
  img {
    height: 93px !important;
    width: auto !important;
    filter: none !important;
  }
}

@media screen and (max-width: $max-mobile-width) {
  .attachment_container {
    min-width: 75vw;
  }
}

:global(.info-container > .file-item-footer) {
  align-items: unset !important;
}
