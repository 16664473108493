@import './app';

.buttons_container {
  display: flex;
  justify-content: flex-end;
  padding: 15px 30px;
  gap: 20px;
}

.btn {
  font-size: $font-size-2xl !important;
  text-transform: capitalize !important;
  font-weight: $bold !important;
  height: 35px;
  width: 120px;
  box-shadow: none !important;
  span {
    transition: scale 100ms ease 0s;
  }
  &:hover {
    span {
      scale: 1.14;
    }
  }
}
.button_upload {
  border: none;
  background-color: transparent;
  padding-left: 15px;
}
.save_button {
  background-color: $primary-2-600 !important;
  &:hover {
    span {
      scale: 1.12;
    }
  }
}
.cancel_button {
  background-color: $white !important;
  color: $neutrals-900 !important;
  border: 1px solid $neutrals-600 !important;
  margin-left: 17px;
  &:hover {
    span {
      scale: 1.12;
    }
  }
}

.filter_btn {
  background-color: $primary-2-300 !important;
  color: $white !important;
  text-transform: capitalize !important;
  padding-inline: 10px !important;
  border-radius: 10px;
  display: flex;
  gap: 10px;
  span,
  svg {
    transition: scale 100ms ease;
  }
}

.filter_btn:hover {
  span,
  svg {
    scale: 1.1;
  }
}

.table_view_btn {
  background-color: $primary-100 !important;
  color: $primary-900 !important;
}

.table_edit_btn {
  background-color: $secondary-100 !important;
  color: $secondary-900 !important;
}

.table_delete_btn {
  background-color: $primary-2-100 !important;
  color: $primary-2-800 !important;
}
.edit_btn,
.delete_btn,
.goBackBtn {
  text-transform: capitalize !important;
}

.goBackBtn {
  background: $white !important;
  color: $primary-2-600 !important;
  border: 1px solid $primary-2-600 !important;
  border-radius: 5px !important;
  .goBack {
    font-size: 14px !important;
    color: #f98a0c;
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
}

.edit_btn {
  color: $secondary-700 !important;
  border: 1px solid $secondary-700 !important;
  font-size: medium;
  border-radius: 5px !important;
  span {
    transition: scale 100ms ease;
  }
  &:hover {
    background-color: transparent !important;
    span {
      scale: 1.1;
    }
  }
}

.delete_btn {
  background: $danger !important;
  color: $white;
  border: 1px solid $danger !important;
  border-radius: 5px !important;
  box-shadow: none !important;
}

.disabled_btn {
  background-color: $neutrals-300 !important;
  color: $white !important;
}

@media only screen and (max-width: $max-mobile-width) {
  .btn {
    font-size: $font-size-lg !important;
  }
  .mobile_button_width {
    width: -webkit-fill-available;
  }
  .buttonContainer {
    flex-direction: column-reverse;
  }

  .goBackBtn {
    display: none !important;
  }

  .backArrow {
    margin-right: 25% !important;
  }
}

@media only screen and (max-width: $min-mobile-width) {
  .buttonContainer {
    flex-direction: column-reverse;
  }

  .goBackBtn {
    display: none !important;
  }

  .backArrow {
    margin-right: 15% !important;
  }
}
