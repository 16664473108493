@import '../../../assets/scss/app';

.timeLineContainer {
  position: absolute;
  width: 100%;
}

.action,
.noRecord {
  color: $neutrals-400;
}

.action {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.showHideBtn {
  color: $primary-600;
  cursor: pointer;
  &:hover {
    color: $primary-400;
    text-decoration: underline;
  }
}

.loader {
  color: $primary-300 !important;
}

.parentTag {
  padding-inline: 6px;
  border-radius: 3px;
  background-color: $success;
  color: $white;
  margin-right: 5px;
  font-size: 12px;
}

.noRecord {
  text-align: center;
}
.toasterId {
  display: none;
}

@media only screen and (max-width: $min-tablet-lg-width) {
  .timeLineContainer {
    position: unset;
  }
}
