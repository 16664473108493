@import '../../../assets/scss/app';

.card {
  background-color: $white;
  border-radius: 4px;
  height: auto;
}

.checkbox_container {
  width: 20px;
}

.checkbox_label_container {
  justify-content: flex-start !important;
}

.checkBox > svg {
  color: #091e42;
  width: 21px;
}

.users_grid_container {
  max-height: 309px;
  display: grid;
  grid-template-columns: 247px 244px;
}

.branches_grid_container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 15px;
  padding: 10px 10px 0px 10px;
  margin: 10px;
}

.dropdown {
  height: 38px !important;
  width: 100%;
  outline: none;
  background-color: $white;
  border: 1px solid $neutrals-300;
  padding: 10px;
}

.grid_item {
  color: $neutrals-600;
}

.filter_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.filter_modal {
  height: auto;
}

.filter_name {
  font-weight: $semibold;
  font-size: $font-size-3xl;
  padding-block-end: 10px;
}

.clear_all {
  align-self: center;
  text-decoration: underline;
  color: $dark-blue;
  padding-inline-start: 5px;
}

.clear_all:hover {
  cursor: pointer;
}

.apply_button {
  border: none !important;
  font-size: $font-size-md !important;
  border-radius: 20px !important;
  width: fit-content !important;
  background-color: $primary-600 !important;
  color: $white !important;
  margin-right: 10px !important;
}

.button_container {
  display: flex;
  justify-content: space-between;
  margin-block-start: 10px;
  margin-inline: 10px;
}

.textField {
  width: 100% !important;
}

.cityTextField {
  > input {
    color: $neutrals-400;
    font-size: $font-size-md;
  }
}

@media only screen and (max-width: $max-mobile-width) {
  .card {
    width: 95vw;
    height: 95vh;
    overflow: hidden !important;
    padding: 10px !important;
    margin-left: unset;
  }
  .users_grid_container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: unset;
  }
  .button_container {
    position: absolute;
    bottom: 0;
    margin-block-end: 5px;
    width: 100%;
  }

  .filter_modal > div:nth-child(3) {
    max-width: 100%;
    max-height: 100%;
    top: 0 !important;
    left: 0 !important;
  }

  .filter_modal > div:nth-child(3) > ul {
    width: 100vw !important;
    max-height: 100%;
  }

  .filter_container {
    display: flex;
    justify-content: space-between;
  }
}
