@import '../../../assets/scss/app';

.parent_asset_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 20px;
  padding: 20px 30px;
  border: 1px solid $neutrals-300;

  .parent_asset_name {
    display: flex;
    align-items: center;
    gap: 15px;
    font-weight: 500;
    font-size: 16px;
    color: $neutrals-900;

    span:first-child {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }
  .parent_asset_link {
    color: $primary-800;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .parent_flag {
    background-color: $primary-50;
    color: $primary-600;
    padding: 10px;
  }
}

.linkingActionsContainer {
  display: flex;
  margin: 20px;

  button {
    background: $primary-600;
    color: $white;
    border: none;
    padding: 10px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.linkedProdActionBtn {
  display: flex;
  margin-bottom: 5px;
}

button {
  background: $primary-600;
  color: $white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding-block: 15px;
  padding-inline: 20px;
}

.linkingBox {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
  height: 100%;

  .linkingContainer {
    background: $white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 12px;
    min-width: 400px;

    .linkingHeader {
      font-size: 30px;
    }

    .linkingContent {
      padding-top: 40px;
      padding-bottom: 10px;
      display: flex;
      align-items: center;

      span {
        font-size: 16px;
        margin-right: 20px;
      }

      input {
        font-size: 16px;
        flex-grow: 1;
        min-width: 300px;
        padding: 2px;
        padding-left: 5px;
      }

      .linkInput:focus {
        outline: none;
      }
    }

    .linkingActions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        margin-left: 12px;
        background: $primary-600;
        color: $white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}

.modal_header_icon {
  padding-right: 15px;
  color: $primary-2-600;
}

.tab_header_name {
  font-size: 20px;
  color: $neutrals-900;
  font-weight: 600;
}

.details_table {
  flex-basis: 70%;
  height: fit-content;
  border: 1px solid $neutrals-300;
  border-bottom: none;
  border-spacing: unset;
  margin-bottom: 60px;
  width: -webkit-fill-available;

  .detail_row {
    display: flex;
    border-bottom: 1px solid $neutrals-300;

    .detail_key,
    .detail_value {
      display: flex;
      align-items: center;
      padding-block: 20px;
      padding-inline: 25px;
      color: $neutrals-800;
      font-size: 16px;
      font-weight: 500;
    }

    .detail_key {
      color: $neutrals-600;
      flex-basis: 35%;
      border-inline-end: 1px solid $neutrals-300;
    }

    .detail_value {
      color: $neutrals-800;
      flex-basis: 70%;
    }
  }
}

.durationSelect {
  display: flex;
  width: 100%;
  border: 1px solid $black;
  align-items: center;
  border-radius: 3px;

  > input {
    flex-grow: 1;
    outline: none;
    border-radius: 3px;
    border: none;
    padding: 5px;
  }

  > span {
    border-left: 1px solid $black;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 4px;
    padding-top: 4px;
  }
}

.warrDatePickerDiv {
  border-radius: 3px;
  border: 1px solid $black;
  flex-grow: 1;

  .warrDatePicker {
    padding: 0px;

    > div {
      > input {
        padding: 3px;
      }

      > fieldset {
        border: none;
      }
    }
  }
}

.grid_container {
  margin-left: 0 !important;
}

.button_upload {
  border: 0px;
  height: auto;
  margin-block: 0px;
  min-width: 400px;
}

.suffix_icon {
  display: flex;
  align-items: center;
  background-color: $pale-cyan;
  padding-inline: 10px;
  border-radius: 0 10px 10px 0;
  z-index: 2;
}

.action_buttons_container {
  display: flex;
  gap: 15px;
}

.action_button {
  width: 28px;
  height: 28px;
  min-width: 20px !important;
  border-radius: 3px !important;
}

.tableHeader {
  align-items: center;
}
.tabAddButton {
  justify-content: flex-end;
  > button {
    align-self: flex-end;
    margin-bottom: 5px;
  }
}

.addButton {
  width: auto;
  padding-right: 15px !important;
}
.addButton:hover {
  span {
    scale: 1.1 !important;
  }
}
@media screen and (max-width: $max-mobile-width) {
  .addButton {
    width: min-content;
  }
}

.ModalContentActions {
  border: 1px solid $neutrals-300;
  display: flex;
  align-items: center;
  background-color: $pale-cyan;
  padding-inline: 10px;
  border-radius: 0 10px 10px 0;
  z-index: 2;
  flex-wrap: nowrap;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $primary-2-300;
    border-radius: 3px;
    padding: 8px;
    cursor: pointer;

    > svg {
      color: $primary-2-300;
      font-size: 20px;
    }
  }

  > span:hover {
    border: 1px solid $primary-2-600;

    > svg {
      color: $primary-2-600;
    }
  }
}

///Reserve Tab

.reserve_tab_container {
  > div {
    > div:nth-child(2) {
      height: 88vh !important;
    }
  }
}

.reserve_tab_container button {
  background-color: $primary-50 !important;
  color: $black !important;
  margin-inline: 5px !important;
  border: unset !important;
  box-shadow: unset !important;
}

.events {
  background-color: $primary-50 !important;
  border: unset !important;
}

.infoIcon {
  height: 120px;
  width: 120px;
  > svg {
    color: $neutrals-600;
    width: 100%;
    height: 100%;
  }
}
.emptyMsg {
  text-align: center;
  padding-inline: 20px;
  padding-bottom: 36px;
}

@media only screen and (max-width: $min-tablet-lg-width) {
  .parent_asset_name {
    flex-direction: column;
    align-items: flex-start !important;

    span {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }
}

@media only screen and (max-width: $max-mobile-width) {
  .tabAddButton {
    align-items: flex-end;
    .addButton {
      padding-right: 0 !important;
      padding-left: 0 !important;
      svg {
        margin: 0;
      }
    }
  }
  .headerContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-inline-end: 20px;
  }

  .tableHeader {
    flex-direction: row;
    justify-content: space-between;
    padding-inline: 5px;
    gap: 10px;
    flex-wrap: wrap;
  }

  .linkedProdActionBtn {
    flex: 1 0 60%;
    justify-content: flex-end;
  }

  .parent_asset_container {
    flex-direction: column;
    gap: 20px;

    div {
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
}

.add_button {
  width: 200px !important;
}

.btn_checkout {
  width: 160px;
}
