@import '../../assets/scss/_app.scss';

.undo_button {
  background: transparent;
  color: $dark-blue;
  font-weight: $medium;
  padding: none !important;
}

.close_icon {
  height: 16px !important;
  width: 14px;

  :hover {
    cursor: pointer;
    color: $black;
  }
}

// toaster custom style for delete success

.toast_failure > div {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: $neutrals-600 !important;
}

.toast_failure_progressbar {
  background: $success !important;
}

.toast_delete_icon {
  size: 24px;
  padding-inline: 10px;
  color: $danger;
}
