@import '../../../assets/scss/app';

.text_field {
  box-sizing: border-box;
  outline: none;
  position: relative;
  padding: 4px 15px 4px 15px;
  border: none;
  width: 100%;
  font-size: 16px;
  line-height: 21px;
  height: 100%;
  border-radius: 2px;
  background: transparent;
  color: $neutrals-500;
  font-weight: 500;
}

.input_container {
  height: 80%;
  display: block;
  outline: none;
  position: relative;
  margin-block: 0;
}

.container {
  display: flex;
  width: 100%;
}

.required {
  right: 8px;
  top: 20px;
  z-index: 0;
  color: $primary-2-600;
}

.disable {
  background-color: $neutrals-100;
}
