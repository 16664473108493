@import '../../assets/scss/_app.module.scss';
@import '../../assets/scss/_button.module.scss';

.header {
  justify-content: space-between;
  flex-direction: row;

  .header_name_container {
    display: flex;
    align-items: center;
  }
  .filter_container {
    display: flex;
    gap: 10px;
  }
}

.CreateInventoryProperties {
  width: auto;
  min-width: 90px !important;
  span {
    font-size: 15px;
  }
}

.tab {
  display: flex;
  gap: 10px;
}

.category_container {
  display: flex;
  margin-bottom: 5px;
  > label {
    margin-top: 8px;
    margin-right: 10px;
  }
  .category_dropdown {
    width: 200px;
  }
}

.tabHeader {
  display: flex;
  align-items: center;
  margin: 15px 20px;
  margin-top: 0;
  border-bottom: 1px solid $neutrals-300;
  overflow: auto;

  .selectedTab {
    opacity: 1;
    border-bottom: 3px solid $primary-2-600;
    position: relative;
    font-weight: 500;
  }
  & > span {
    opacity: 0.5;
    cursor: pointer;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
  }
}

.tabHeader::-webkit-scrollbar {
  display: none;
}

.tab_container {
  padding-inline: 20px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: auto;
}

@media only screen and (max-width: $min-tablet-lg-width) {
  .header {
    .header_name_container {
      align-self: flex-start;
    }
    .filter_container {
      flex-wrap: wrap;
      justify-content: flex-end;
      width: 100%;
    }
  }
}

@media only screen and (max-width: $max-mobile-width) {
  .CreateInventoryProperties {
    min-width: 64px !important;

    span {
      display: none;
    }
  }
}
