@import '../../assets/scss/app';

.card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  background-color: $white;
  border-radius: 10px;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 25px 5px;

    .trash_can_img {
      height: 45px;
      margin-bottom: 5px;
    }
  }

  .btns_container {
    padding: 10px !important;

    .cancel_btn,
    .delete_btn {
      font-size: 14px !important;
      height: 32px;
      width: 90px;
    }

    .cancel_btn {
      margin-left: 0px !important;
    }
  }
}

.message {
  font-size: 18px;
  font-weight: 500;
  margin-block: 15px;
  text-align: center;
}
.alert_msg {
  color: $danger;
  font-size: 14px;
  padding-inline: 9px;
}
