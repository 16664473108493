@import '../../assets/scss/app';

.card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 70%;
  background-color: $white;
  border-radius: 15px !important;
}
