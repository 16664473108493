// spaces
$spacing-00: 0px;
$spacing-01: 4px;
$spacing-02: 8px;
$spacing-03: 12px;
$spacing-04: 16px;
$spacing-05: 24px;
$spacing-06: 32px;
$spacing-07: 40px;
$spacing-08: 48px;
$spacing-09: 64px;
$spacing-10: 80px;
$spacing-11: 96px;
$spacing-12: 144px;

//margin

.m-0 {
  margin: $spacing-00 !important;
}
.m-1 {
  margin: $spacing-01 !important;
}
.m-2 {
  margin: $spacing-02 !important;
}
.m-3 {
  margin: $spacing-03 !important;
}
.m-4 {
  margin: $spacing-04 !important;
}
.m-5 {
  margin: $spacing-05 !important;
}
.m-6 {
  margin: $spacing-06 !important;
}
.m-7 {
  margin: $spacing-07 !important;
}
.m-8 {
  margin: $spacing-08 !important;
}
.m-9 {
  margin: $spacing-09 !important;
}
.m-10 {
  margin: $spacing-10 !important;
}
.m-11 {
  margin: $spacing-11 !important;
}
.m-12 {
  margin: $spacing-12 !important;
}

//margin-top

.mt-0 {
  margin-top: $spacing-00 !important;
}

.mt-1 {
  margin-top: $spacing-01 !important;
}
.mt-2 {
  margin-top: $spacing-02 !important;
}
.mt-3 {
  margin-top: $spacing-03 !important;
}
.mt-4 {
  margin-top: $spacing-04 !important;
}
.mt-5 {
  margin-top: $spacing-05 !important;
}
.mt-6 {
  margin-top: $spacing-06 !important;
}
.mt-7 {
  margin-top: $spacing-07 !important;
}
.mt-8 {
  margin-top: $spacing-08 !important;
}
.mt-9 {
  margin-top: $spacing-09 !important;
}
.mt-10 {
  margin-top: $spacing-10 !important;
}
.mt-11 {
  margin-top: $spacing-11 !important;
}
.mt-12 {
  margin-top: $spacing-12 !important;
}

//margin-bottom

.mb-1 {
  margin-bottom: $spacing-01 !important;
}
.mb-2 {
  margin-bottom: $spacing-02 !important;
}
.mb-3 {
  margin-bottom: $spacing-03 !important;
}
.mb-4 {
  margin-bottom: $spacing-04 !important;
}
.mb-5 {
  margin-bottom: $spacing-05 !important;
}
.mb-6 {
  margin-bottom: $spacing-06 !important;
}
.mb-7 {
  margin-bottom: $spacing-07 !important;
}
.mb-8 {
  margin-bottom: $spacing-08 !important;
}
.mb-9 {
  margin-bottom: $spacing-09 !important;
}
.mb-10 {
  margin-bottom: $spacing-10 !important;
}
.mb-11 {
  margin-bottom: $spacing-11 !important;
}
.mb-12 {
  margin-bottom: $spacing-12 !important;
}

//margin-right

.mr-1 {
  margin-right: $spacing-01 !important;
}
.mr-2 {
  margin-right: $spacing-02 !important;
}
.mr-3 {
  margin-right: $spacing-03 !important;
}
.mr-4 {
  margin-right: $spacing-04 !important;
}
.mr-5 {
  margin-right: $spacing-05 !important;
}
.mr-6 {
  margin-right: $spacing-06 !important;
}
.mr-7 {
  margin-right: $spacing-07 !important;
}
.mr-8 {
  margin-right: $spacing-08 !important;
}
.mr-9 {
  margin-right: $spacing-09 !important;
}
.mr-10 {
  margin-right: $spacing-10 !important;
}
.mr-11 {
  margin-right: $spacing-11 !important;
}
.mr-12 {
  margin-right: $spacing-12 !important;
}

//margin-left

.ml-0 {
  margin-left: $spacing-00 !important;
}

.ml-1 {
  margin-left: $spacing-01 !important;
}
.ml-2 {
  margin-left: $spacing-02 !important;
}
.ml-3 {
  margin-left: $spacing-03 !important;
}
.ml-4 {
  margin-left: $spacing-04 !important;
}
.ml-5 {
  margin-left: $spacing-05 !important;
}
.ml-6 {
  margin-left: $spacing-06 !important;
}
.ml-7 {
  margin-left: $spacing-07 !important;
}
.ml-8 {
  margin-left: $spacing-08 !important;
}
.ml-9 {
  margin-left: $spacing-09 !important;
}
.ml-10 {
  margin-left: $spacing-10 !important;
}
.ml-11 {
  margin-left: $spacing-11 !important;
}
.ml-12 {
  margin-left: $spacing-12 !important;
}

//margin X

.mx-1 {
  margin-inline: $spacing-01 !important;
}
.mx-2 {
  margin-inline: $spacing-02 !important;
}
.mx-3 {
  margin-inline: $spacing-03 !important;
}
.mx-4 {
  margin-inline: $spacing-04 !important;
}
.mx-5 {
  margin-inline: $spacing-05 !important;
}
.mx-6 {
  margin-inline: $spacing-06 !important;
}
.mx-7 {
  margin-inline: $spacing-07 !important;
}
.mx-8 {
  margin-inline: $spacing-08 !important;
}
.mx-9 {
  margin-inline: $spacing-09 !important;
}
.mx-10 {
  margin-inline: $spacing-10 !important;
}
.mx-11 {
  margin-inline: $spacing-11 !important;
}
.mx-12 {
  margin-inline: $spacing-12 !important;
}

//margin Y

.my-1 {
  margin-block: $spacing-01 !important;
}
.my-2 {
  margin-block: $spacing-02 !important;
}
.my-3 {
  margin-block: $spacing-03 !important;
}
.my-4 {
  margin-block: $spacing-04 !important;
}
.my-5 {
  margin-block: $spacing-05 !important;
}
.my-6 {
  margin-block: $spacing-06 !important;
}
.my-7 {
  margin-block: $spacing-07 !important;
}
.my-8 {
  margin-block: $spacing-08 !important;
}
.my-9 {
  margin-block: $spacing-09 !important;
}
.my-10 {
  margin-block: $spacing-10 !important;
}
.my-11 {
  margin-block: $spacing-11 !important;
}
.my-12 {
  margin-block: $spacing-12 !important;
}

//padding

.p-1 {
  padding: $spacing-01 !important;
}
.p-2 {
  padding: $spacing-02 !important;
}
.p-3 {
  padding: $spacing-03 !important;
}
.p-4 {
  padding: $spacing-04 !important;
}
.p-5 {
  padding: $spacing-05 !important;
}
.p-6 {
  padding: $spacing-06 !important;
}
.p-7 {
  padding: $spacing-07 !important;
}
.p-8 {
  padding: $spacing-08 !important;
}
.p-9 {
  padding: $spacing-09 !important;
}
.p-10 {
  padding: $spacing-10 !important;
}
.p-11 {
  padding: $spacing-11 !important;
}
.p-12 {
  padding: $spacing-12 !important;
}

//padding-top

.pt-0 {
  padding-top: $spacing-00 !important;
}
.pt-1 {
  padding-top: $spacing-01 !important;
}
.pt-2 {
  padding-top: $spacing-02 !important;
}
.pt-3 {
  padding-top: $spacing-03 !important;
}
.pt-4 {
  padding-top: $spacing-04 !important;
}
.pt-5 {
  padding-top: $spacing-05 !important;
}
.pt-6 {
  padding-top: $spacing-06 !important;
}
.pt-7 {
  padding-top: $spacing-07 !important;
}
.pt-8 {
  padding-top: $spacing-08 !important;
}
.pt-9 {
  padding-top: $spacing-09 !important;
}
.pt-10 {
  padding-top: $spacing-10 !important;
}
.pt-11 {
  padding-top: $spacing-11 !important;
}
.pt-12 {
  padding-top: $spacing-12 !important;
}

//padding-bottom

.pb-0 {
  padding-bottom: $spacing-00 !important;
}
.pb-1 {
  padding-bottom: $spacing-01 !important;
}
.pb-2 {
  padding-bottom: $spacing-02 !important;
}
.pb-3 {
  padding-bottom: $spacing-03 !important;
}
.pb-4 {
  padding-bottom: $spacing-04 !important;
}
.pb-5 {
  padding-bottom: $spacing-05 !important;
}
.pb-6 {
  padding-bottom: $spacing-06 !important;
}
.pb-7 {
  padding-bottom: $spacing-07 !important;
}
.pb-8 {
  padding-bottom: $spacing-08 !important;
}
.pb-9 {
  padding-bottom: $spacing-09 !important;
}
.pb-10 {
  padding-bottom: $spacing-10 !important;
}
.pb-11 {
  padding-bottom: $spacing-11 !important;
}
.pb-12 {
  padding-bottom: $spacing-12 !important;
}

//padding-right

.pr-0 {
  padding-right: $spacing-00 !important;
}

.pr-1 {
  padding-right: $spacing-01 !important;
}
.pr-2 {
  padding-right: $spacing-02 !important;
}
.pr-3 {
  padding-right: $spacing-03 !important;
}
.pr-4 {
  padding-right: $spacing-04 !important;
}
.pr-5 {
  padding-right: $spacing-05 !important;
}
.pr-6 {
  padding-right: $spacing-06 !important;
}
.pr-7 {
  padding-right: $spacing-07 !important;
}
.pr-8 {
  padding-right: $spacing-08 !important;
}
.pr-9 {
  padding-right: $spacing-09 !important;
}
.pr-10 {
  padding-right: $spacing-10 !important;
}
.pr-11 {
  padding-right: $spacing-11 !important;
}
.pr-12 {
  padding-right: $spacing-12 !important;
}

//padding-left

.pl-0 {
  padding-left: $spacing-00 !important;
}

.pl-1 {
  padding-left: $spacing-01 !important;
}
.pl-2 {
  padding-left: $spacing-02 !important;
}
.pl-3 {
  padding-left: $spacing-03 !important;
}
.pl-4 {
  padding-left: $spacing-04 !important;
}
.pl-5 {
  padding-left: $spacing-05 !important;
}
.pl-6 {
  padding-left: $spacing-06 !important;
}
.pl-7 {
  padding-left: $spacing-07 !important;
}
.pl-8 {
  padding-left: $spacing-08 !important;
}
.pl-9 {
  padding-left: $spacing-09 !important;
}
.pl-10 {
  padding-left: $spacing-10 !important;
}
.pl-11 {
  padding-left: $spacing-11 !important;
}
.pl-12 {
  padding-left: $spacing-12 !important;
}

//padding X

.px-1 {
  padding-inline: $spacing-01 !important;
}
.px-2 {
  padding-inline: $spacing-02 !important;
}
.px-3 {
  padding-inline: $spacing-03 !important;
}
.px-4 {
  padding-inline: $spacing-04 !important;
}
.px-5 {
  padding-inline: $spacing-05 !important;
}
.px-6 {
  padding-inline: $spacing-06 !important;
}
.px-7 {
  padding-inline: $spacing-07 !important;
}
.px-8 {
  padding-inline: $spacing-08 !important;
}
.px-9 {
  padding-inline: $spacing-09 !important;
}
.px-10 {
  padding-inline: $spacing-10 !important;
}
.px-11 {
  padding-inline: $spacing-11 !important;
}
.px-12 {
  padding-inline: $spacing-12 !important;
}

//padding Y

.py-1 {
  padding-block: $spacing-01 !important;
}
.py-2 {
  padding-block: $spacing-02 !important;
}
.py-3 {
  padding-block: $spacing-03 !important;
}
.py-4 {
  padding-block: $spacing-04 !important;
}
.py-5 {
  padding-block: $spacing-05 !important;
}
.py-6 {
  padding-block: $spacing-06 !important;
}
.py-7 {
  padding-block: $spacing-07 !important;
}
.py-8 {
  padding-block: $spacing-08 !important;
}
.py-9 {
  padding-block: $spacing-09 !important;
}
.py-10 {
  padding-block: $spacing-10 !important;
}
.py-11 {
  padding-block: $spacing-11 !important;
}
.py-12 {
  padding-block: $spacing-12 !important;
}
