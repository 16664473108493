@import '../../assets/scss/app';

.images_container {
  display: flex;
  gap: 20px;
}

.image_container {
  position: relative;
}

.headerContainer {
  justify-content: initial;
  align-items: center;
}

.remove_btn_container {
  position: absolute;
  top: -8px;
  right: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: $neutrals-500;
  color: $white;
}

.gridContainer {
  position: relative;
  padding-right: 20px;
}

.imageUploadContainer {
  width: 100%;
}

.sub_title {
  white-space: break-spaces;
}

.remove_btn {
  cursor: pointer;
}

.iconInputField {
  border-left: 0;
  border-radius: 0px 2px 2px 0px;
}

.icon {
  border-radius: 2px 0px 0px 2px;
  height: 36px;
}

.time_picker {
  div {
    height: inherit;
    input {
      width: 80px;
    }
  }
}

.gridKeyName {
  white-space: pre-wrap;
}

.gridTitleName {
  justify-content: flex-start;
  align-items: center;
}

.addButtonContainer {
  padding-left: 15px !important;
}

.grid_textArea {
  > textarea {
    max-width: 71vw;
    outline: none;
    height: 70px;
    padding: 10px;
  }
}

.grid_textField {
  height: 36px;
  display: flex;
  align-items: center;
}

.btn {
  width: 100%;
  background-color: $primary-2-600 !important;
  font-size: inherit !important;
  height: 38px;
}

@media only screen and (max-width: $min-tablet-lg-width) {
  .headerContainer {
    flex-direction: row;
  }
}

@media only screen and (max-width: $min-tablet-lg-width) {
  .headerContainer {
    flex-direction: row;
  }
}

@media screen and (max-width: $max-mobile-width) {
  .grid_textArea {
    > textarea {
      width: -webkit-fill-available !important;
      max-width: -webkit-fill-available;
    }
  }

  .grid_textField {
    padding-top: 0px !important;
  }

  .addButtonContainer {
    align-items: center !important;
  }
}

@media screen and (max-width: $max-tablet-width) {
  .grid_textArea {
    > textarea {
      width: -webkit-fill-available !important;

      max-width: -webkit-fill-available;
    }
  }
}
