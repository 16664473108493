@import '../../../assets/scss/app';

.status_container {
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 700;
  color: $white;
  cursor: pointer;
  user-select: none;
  & > span {
    display: block;
    transition: scale 100ms ease;
  }
  &:hover {
    span {
      scale: 1.14;
    }
  }
}
.status_container_available:hover {
  span {
    scale: 1;
  }
}
