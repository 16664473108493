@import '../../assets/scss/app';

.backLinkContainer {
  margin-block: 10px;
  display: flex;
  justify-content: center;

  .backLink {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #1694c0;
    &:hover {
      color: #03367e;
    }

    > svg {
      font-size: 1rem;
    }
  }
}

.resendMailContainer {
  text-align: center;
}

.openMail {
  cursor: pointer;
  color: #1694c0;
  &:hover {
    color: #03367e;
  }
}

.checkMailImgContainer {
  margin-block: 20px;
  height: 150px;
  display: flex;
  justify-content: center;

  .checkMail {
    height: 100%;
  }
}
