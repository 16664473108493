$primary-50: #e0f8fa;
$primary-100: #b3edf1;
$primary-300: #50d5e1;
$primary-400: #29ccdb;
$primary-500: #00c3d6;
$primary-600: #00b3c3;
$primary-800: #029da9;
$primary-900: #036666;
$primary-2-100: #ffdfb3;
$primary-2-300: #feb54f;
$primary-2-600: #f98a0c;
$primary-2-800: #ed6b0a;
$secondary-100: #e6f2c0;
$secondary-300: #c6e16d;
$secondary-500: #afd42b;
$secondary-800: #879611;
$secondary-700: #96ad1b;
$secondary-900: #6e7000;
$neutrals-100: #f2f4f7;
$neutrals-200: #eaecf0;
$neutrals-300: #d0d5dd;
$neutrals-400: #98a2b3;
$neutrals-500: #667085;
$neutrals-600: #475467;
$neutrals-700: #344054;
$neutrals-800: #1d2939;
$neutrals-900: #101828;
$white: #ffffff;
$black: #000000;
$danger: #f97066;
$warning: #fdb022;
$info: #36bffa;
$success: #12b76a;
$pale-cyan: #f2f8f9;
$pale-aqua: #bfd8d8;
$shadow: #ecf5f6;
$dark-blue: #00205a;
$peacock-blue: #13678a;
$orange: #fb8a0a;
$misty-rose: #ffe5e3;
$pale-red: #df5449;
$yellow: #eded2c;

:export {
  primary_400: $primary-400;
  primary_500: $primary-500;
  primary_800: $primary-800;
  primary_2_300: $primary-2-300;
  secondary_500: $secondary-500;
  neutrals_400: $neutrals-400;
  neutrals_500: $neutrals-500;
  neutrals_100: $neutrals-100;
  peacockBlue: $peacock-blue;
  warning: $warning;
  danger: $danger;
  orange: $orange;
  green: $success;
  yellow: $yellow;
  paleRed: $pale-red;
  black: $black;
  white: $white;
}
