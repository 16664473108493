@import '../../../assets/scss/app';

.navbar_auto_show,
.navbar_manual_show {
  overflow-y: scroll;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 240px;
  height: calc(100vh - 108px);
  background-color: $white;
  z-index: 1;
  transition: 0.3s ease-in;
}

.navbar_auto_show::-webkit-scrollbar,
.navbar_manual_show::-webkit-scrollbar {
  display: none;
}
.hide_navbar {
  min-width: 77px !important;
  width: 77px !important;
  overflow-x: hidden !important;
  transition: 0.3s ease-in;
}

.employee_company_name:hover {
  cursor: default;
}

.company_detail {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.company_menu_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: $primary-600;
  border-radius: 50%;
  cursor: pointer;
}

.hide_bar_arrow_btn {
  margin-bottom: 10px;
  margin-inline-start: 30px;
  cursor: pointer;
}

.menu_item_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
  min-width: 20px;
  min-height: 20px;
  position: relative;

  .notification_badge {
    position: absolute;
    top: -15px;
    right: -30px;
    padding: 0px 3px 0px 6px;
    border-radius: 30%;
    background-color: $primary-800;
    color: $white;
    font-size: 0.8rem;
    height: 20px;
  }
  .notification_badge_inactive {
    background-color: $primary-2-800;
  }
  .notification_badge_inactive:hover {
    background-color: $primary-800;
  }
}

// dashboard button

.dashboard_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 85%;
  height: 60px;
  margin-block: 24px 15px;
  margin-inline: auto;
  border-radius: 5px;
  cursor: pointer;
}

.dashboard_btn:hover {
  background-color: $primary-50;
}

.hide_dashboard_btn {
  margin-inline: 20px;
}

.menu_item_arrow {
  display: flex;
  align-items: center;
  color: $neutrals-300;
  cursor: pointer;
}

.menu_item_active_arrow {
  color: $primary-2-600;
  display: flex;
  align-items: center;
}

.mui_icon {
  color: $primary-600;
}

.font_icon {
  width: 20px !important;
  height: 20px !important;
  color: $primary-600;
}

.hover_mui_icon {
  color: $primary-2-300 !important;
}

.hover_menu_icon {
  height: 20px !important;
  width: 20px !important;
  color: $primary-2-300 !important;
}

.hover_menu_arrow_icon {
  color: $primary-2-300 !important;
}

.dashboard_icon {
  width: 26px;
}

.dashboard_txt {
  color: $neutrals-900;
}

// Navbar links

.overall_menu_conatainer {
  overflow-y: scroll;
}

.overall_menu_conatainer::-webkit-scrollbar {
  display: none;
}

.side_menu {
  margin-bottom: 20px;
  transition: ease-out 0.3s;
}

.menu_item_container {
  display: flex;
  align-items: center;
  padding-block: 15px;
  padding-inline: 27px;
  cursor: pointer;
  user-select: none;
  min-height: 48px;
  gap: 15px;
}

.hide_menu_item_container {
  padding-inline: 10px;
  border-radius: 5px;
}

.menu_item_container:hover {
  background-color: $primary-50;
  .notification_badge_inactive {
    background-color: $primary-800;
  }
}

.show_menu_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.hide_menu_item {
  display: none;
}

.menu_item_active {
  background-color: $primary-50;
}

.menu_item_name {
  line-height: 28px;
  color: $neutrals-900;
}
.typography_hover {
  min-width: fit-content;
  width: fit-content;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: $primary-800;
  padding: 10px;
  background-color: $pale-cyan;
}
.popover_item {
  height: 100px;
  margin-bottom: 10px;
}

// Navbar image styles

.navbar_img_container {
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
}

.lines_img_container,
.calendar_img_container {
  display: grid;
}

.lines_img_container {
  align-items: flex-end;
}

.calendar_img_container {
  justify-content: flex-end;
}

.lines_image {
  max-width: 100px;
}

.calendar_image {
  max-width: 120px;
}

.lines_image {
  margin-bottom: 26px;
}

.calendar_image {
  margin-bottom: 207px;
  margin-right: 45px;
}

// Profile details

.navbar_profile_info {
  width: 100%;
}

.profile_info_container {
  display: flex;
  align-items: center;
  margin-left: 31px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  max-width: 269px;
}

.hide_profile_info_container {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 46px;
  max-width: 200px;
}

.profile_info {
  margin-inline: 18px;
}

.profile_pic {
  object-fit: cover;
  width: 58px;
  height: 58px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

.profile_pic:hover {
  cursor: pointer;
}

.profile_name {
  color: $neutrals-900;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile_role {
  line-height: 23px;
  text-align: left;
}

// border line style

.top_border_line,
.bottom_border_line {
  box-sizing: border-box;
  width: 80%;
  margin-inline: auto;
}

.bottom_border_line {
  margin-bottom: 20px;
}

.logoutBtn {
  color: $danger;
}

.logout_menu_container {
  display: none;
  &:hover {
    background-color: #ffe8e6;
  }
}

@media only screen and (max-width: 1600px) {
  .hide_navbar {
    height: calc(100vh - 75px);
    min-width: 77px !important;
  }

  .navbar_manual_show,
  .navbar_auto_show {
    height: calc(100vh - 65px);
    width: 230px;
  }

  .company_menu_btn {
    width: 24px;
    height: 24px;
  }
  .hide_bar_arrow_btn {
    margin-bottom: 10px;
    margin-inline-start: 33px;
    cursor: pointer;
  }

  // dashboard button

  .dashboard_btn {
    max-width: 85%;
    height: 40px;
    margin-block: 20px 12px;
  }

  .hide_dashboard_btn {
    margin-inline: 20px;
  }

  .dashboard_icon {
    width: 18px;
  }

  // Navbar links

  .menu_item_icon {
    .notification_badge {
      right: -10px;
      top: -10px;
    }
  }

  .menu_item_container {
    padding-block: 10px;
    padding-inline: 25px;
  }

  .hide_menu_item_container {
    border-radius: 5px;
  }

  // Navbar image styles

  .lines_image {
    max-width: 60px;
  }

  .calendar_image {
    max-width: 80px;
  }

  .lines_image {
    margin-bottom: 30px;
  }

  .calendar_image {
    margin-bottom: 140px;
    margin-right: 35px;
  }

  // Profile details

  .profile_info_container {
    margin-left: 25px;
    margin-bottom: 25px;
  }

  .hide_profile_info_container {
    margin-left: 20px;
    margin-bottom: 25px;
  }

  .profile_info {
    margin-inline: 12px;
  }

  .profile_pic {
    width: 40px;
    height: 40px;
  }

  // border line style

  .top_border_line,
  .bottom_border_line {
    width: 80%;
  }

  .bottom_border_line {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 1200) {
  .navbar_auto_show {
    height: calc(100vh);
  }
}

@media only screen and (max-width: $max-tablet-width) {
  .navbar_manual_show,
  .navbar_manual_hide {
    height: calc(100vh - 60px);
  }

  .navbar_manual_show {
    transform: translateX(0%);
  }

  .navbar_manual_hide {
    position: absolute;
    transform: translateX(-100%);
  }

  .suffix_icon {
    display: flex;
    align-items: center;
    background-color: $pale-cyan;
    padding-inline: 10px;
    border-radius: 0 10px 10px 0;
    z-index: 2;
  }

  .dashboard_btn {
    border-radius: 5px;
  }

  .top_border_line {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: $max-mobile-width) {
  .navbar_manual_show {
    transform: translateY(0%);
    width: 100%;
  }

  .navbar_manual_hide {
    min-width: 100%;
    transform: translateY(-110%);
  }

  // Company info

  .company_menu_btn {
    width: 22px;
    height: 22px;
  }

  .hide_bar_arrow_btn {
    width: 22px;
    height: 22px;
  }

  // dashboard button

  .dashboard_btn {
    margin-block: 16px 10px;
  }

  .dashboard_icon {
    width: 16px;
  }

  // Navbar links

  .menu_item_container {
    padding-block: 8px;
    padding-inline: 22px;
  }

  .top_border_line,
  .bottom_border_line {
    width: 90%;
  }

  .profile_info_container {
    max-width: unset;
  }

  .profile_name {
    max-width: unset;
  }
  .logout_menu_container {
    display: flex;
  }
}

.border_bottom {
  border-bottom: 1px solid #999;
}
