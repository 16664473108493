@import './assets/scss/app';

html,
body {
  min-height: 100%;
}
body {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: $neutrals-600;
  background-color: $pale-cyan;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

html::-webkit-scrollbar {
  display: none;
}

#root {
  min-height: 100vh;
}
