@import '../../assets/scss/app';

.header {
  justify-content: space-between;
  gap: 20px;

  .header_name_container {
    display: flex;
    align-items: center;
    padding-block: 5px;
  }
}

.tab {
  display: flex;
  gap: 10px;

  .active_count,
  .inactive_count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 25px;
    color: $white;
    border-radius: 5px;
  }

  .active_count {
    background-color: $primary-2-600;
  }

  .inactive_count {
    background-color: $neutrals-300;
  }
}

.tabHeader {
  display: flex;
  align-items: center;
  margin: 15px 20px;
  margin-top: 0;
  border-bottom: 1px solid $neutrals-300;
  overflow-y: hidden;
  overflow-x: scroll;

  .selectedTab {
    opacity: 1;
    border-bottom: 3px solid $primary-2-600;
    position: relative;
    font-weight: 500;
  }
  & > span {
    opacity: 0.5;
    cursor: pointer;
    padding: 0 30px 10px;
  }
}

.tabHeader::-webkit-scrollbar {
  display: none;
}

.tabContainer {
  padding-inline: 20px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: auto;
}

@media only screen and (max-width: $min-tablet-lg-width) {
  .header {
    flex-direction: column;

    .header_name_container {
      align-self: flex-start;
    }
  }
}
