@import '../../../assets/scss/app';

.container {
  display: inline-flex;
  width: 100%;
}

.countrySelect {
  min-width: 55px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px !important;
    padding: unset !important;
    background-color: $primary-50;
    span {
      color: $primary-900;
    }
  }

  fieldset {
    border-radius: 2px 0 0 2px;
    border-right: unset;
    border-color: $neutrals-300 !important;
    border-width: 1px !important;
  }
}

.textField {
  display: flex;
  align-items: center;
  width: inherit;
  border-radius: 0 2px 2px 0;
  span {
    color: $neutrals-500;
    padding-inline: 8px 5px;
  }
  input {
    padding-left: 0px;
  }
}
