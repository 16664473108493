@import '../../assets/scss/app';

.layout {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $pale-cyan;
}

.body_container,
.hide_body_container {
  display: grid;
  height: 100%;
}

.body_container {
  grid-template-columns: 240px 1fr;
  transition: 0.3s ease-in;
}

.full_height_container {
  height: auto !important;
}
.hide_body_container {
  grid-template-columns: 80px 1fr;
  height: calc(100vh - 105px);
  transition: 0.3s ease-in;
}

.main_container {
  margin: 15px;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 105px);
  transition: 0.3s ease-in;
}

@media only screen and (max-width: $max-desktop-lg-width) {
  .body_container {
    grid-template-columns: 230px 1fr;
  }
}

// Tablet

@media only screen and (max-width: $max-tablet-width) {
  .body_container {
    grid-template-columns: 0 1fr;
  }

  .hide_main_container {
    opacity: 0.25;
    div {
      pointer-events: none;
    }
  }

  .main_container {
    height: calc(100vh - 105px);
  }

  .main_container_no_header_filter {
    height: calc(100vh-105px);
  }

  .hide_body_container {
    height: calc(100vh - 150px);
  }

  .body_container_no_header_filter {
    height: calc(100vh - 105px);
  }
}

// Mobile

@media only screen and (max-width: $max-mobile-width) {
  .main_container {
    margin: 15px 10px;
    height: calc(100vh - 120px);
  }
  .hide_main_container {
    opacity: 0.25;
    div {
      pointer-events: none;
    }
  }
  .hide_body_container {
    grid-template-columns: 0 1fr;
  }
}
