@import '../../assets/scss/app';

.tablePaperContainer {
  height: 100%;
  overflow: auto;
  div {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.tablePaperContainer li:focus {
  background-color: $primary-50 !important;
}

.tablePaperContainer li:hover {
  background-color: $primary-50 !important;
}

.emptyTable {
  min-height: 200px;
  height: 100%;
  thead {
    height: 46px;
  }
}
.tableContainer {
  height: calc(100% - 36px) !important;
  overflow: auto;
}

.tableContainer::-webkit-scrollbar {
  display: none;
}

.selected {
  background-color: $neutrals-100 !important;
}

.table {
  table-layout: fixed;
  border-collapse: separate !important;
}

.reportsPageTable {
  width: max-content !important;
  min-width: 100%;
  table-layout: unset;
}

.table_head .head_cell {
  position: sticky;
  top: 0;
  z-index: 1;
}

.table_cell {
  // word-break: break-word;
  font-size: $font-size-md !important;
  padding: 10px !important;
  text-align: left !important;
}

.breakWord {
  word-break: break-word;
}

.reportPageTableCells {
  max-width: 400px;
}

.head_cell {
  background-color: $primary-50;
  border-top: 1px solid $neutrals-300;
  padding: 10px !important;
  font-weight: 500 !important;
  color: $primary-900 !important;
  font-size: $font-size-lg !important;
  text-align: left !important;
}

.loader_row {
  border-right: 1px solid $neutrals-300;
}
.loader_container {
  border-bottom: none !important;
}

.no_data_found_msg {
  font-weight: 500 !important;
  font-size: $font-size-xl !important;
  color: $neutrals-900 !important;
  border-bottom: none !important;
}

.headerContainer {
  display: flex;
  .headerText {
    font-size: $font-size-lg;
  }

  :nth-last-child(2) {
    padding-right: 15px;
  }

  .headerSortIcon {
    cursor: pointer;
  }
}

.justifyContentCenter {
  justify-content: center;
}

.justifyContentbetween {
  justify-content: space-between;
}

.icon_disabled {
  color: $neutrals-400;
}

.active_icon {
  height: 15px;

  > span {
    padding: 20px;
    border: 1px solid $neutrals-300;
  }
}
.paginate_container {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;

  .paginate_dropdown {
    display: flex;
    align-items: center;
    gap: 15px;

    .filterSelector {
      bottom: 30px;
      right: 0;
      top: unset;
    }
  }

  .paginate_buttons_container {
    display: flex;
    align-items: center;
    gap: 13px;

    .paginate_buttons {
      display: flex;
      align-items: center;
      gap: 15px;

      button {
        padding: 0px !important;
        width: 28px;
        height: 28px;
      }

      .hide_btn {
        display: none;
      }

      .prevBtn,
      .nextBtn {
        border: 1px solid $neutrals-300;
        border-radius: 5px;
      }

      .midBtn {
        background-color: $primary-2-600;
        color: $white;
        min-width: 0px !important;
        box-shadow: none;
      }
    }
  }
}

.table_side_menu_open {
  table-layout: unset;
  .headerContainer {
    flex-wrap: nowrap;

    .headerText {
      white-space: nowrap;
    }
  }
}

.onclick_cursor {
  color: $primary-800;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

@media only screen and (max-width: $max-tablet-width) {
  .table {
    table-layout: unset;
  }
  .headerContainer {
    flex-wrap: nowrap;
    .headerText {
      white-space: nowrap;
    }
  }
  .table_cell {
    word-break: keep-all;
  }
}

@media only screen and (max-width: $min-mobile-width) {
  .paginate_buttons_container {
    gap: 7px !important;
  }
  .paginate_buttons {
    gap: 4px !important;
    button {
      width: 24px !important;
    }
  }
}
