@import '../../../assets/scss/app';

.card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  border-radius: 15px !important;
  width: 50%;

  .buttons_container {
    padding: 30px;

    .btn {
      height: 35px;
    }
  }
}

@media screen and (max-width: $max-tablet-width) {
  .card {
    width: 70%;
  }
}

@media screen and (max-width: $min-tablet-lg-width) {
  .card {
    width: 85%;
  }
}
