@import '../../assets/scss/app';

.text_field {
  box-sizing: border-box;
  padding-left: 16px;
  height: 38px !important;
  background-color: $white;
  border: 1px solid $neutrals-300;
  outline: none;
  display: flex;
  font-weight: 400;
  font-size: 16px;
  color: $black;
  width: 100%;
}

.headerContainer {
  justify-content: unset;
  align-items: center;
}

.cityTextField {
  color: $neutrals-400;
}

.dropdown {
  width: 100%;
}

.containerGrid {
  align-items: flex-start;
}

.footerHorizontalLine {
  > hr {
    margin: inherit;
  }
}

.myBranchTag {
  background-color: $primary-50;
  color: $primary-800;
  padding: 2px 6px;
  border: 1px solid;
  border-radius: 50px;
  margin-left: 5px;
  font-size: 0.7rem;
  text-align: center;
  white-space: nowrap;
}

// Tablet

@media only screen and (max-width: $min-tablet-lg-width) {
  .headerContainer {
    flex-direction: row;
  }
}
// Mobile
@media only screen and (max-width: $min-tablet-lg-width) {
  .headerContainer {
    flex-direction: row;
  }
}

@media only screen and (max-width: $max-mobile-width) {
  .gridTitle {
    padding-bottom: 8px;
  }

  .buttons_container {
    flex-direction: row;
  }
}
