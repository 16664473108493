@import '../../assets/scss/app';

.timelineProgress {
  display: flex;
  flex-direction: column;

  .progressStep {
    display: flex;
    gap: 10px;
    justify-content: center;

    .progressLineContainer {
      position: relative;
      justify-content: center;
      display: flex;

      .progressDot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $success;
        cursor: pointer;
        z-index: 1;
        &:hover {
          scale: 1.15;
        }
      }
      .progressLine {
        position: absolute;
        height: 100%;
        background-color: $success;
        width: 5px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0.4;
      }
      .moreDot {
        display: flex;
        flex-direction: column;
        gap: 5px;
        background: $white;
        z-index: 1;
        padding-block: 10px;
        cursor: pointer;
        &:hover {
          .progressDot {
            scale: 1.1;
          }
        }
      }
    }
  }

  .showMore {
    padding-bottom: 40px;
  }

  .emptyProgressContent,
  .progressContent {
    flex-basis: 40%;
  }

  .textAlignRight {
    justify-content: flex-end;
    text-align: right;
  }

  .progressContent {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: -3px;

    .progressName {
      margin-bottom: 6px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;

      &:hover {
        cursor: pointer;
      }

      .addBtnFontSize {
        font-size: 14px;
      }

      .addIcon,
      .addBtn {
        font-size: 18px;
      }

      .addBtn {
        display: flex;
        gap: 5px;
        align-items: center;
        cursor: pointer;
        background-color: $primary-400;
        color: $white;
        border-radius: 5px;
        padding: 3px 10px;
        &:hover {
          scale: 1.05;
        }
      }

      .linkProductBtns {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
      }
    }
    .progressDetails {
      font-size: 14px;
    }
  }
}
