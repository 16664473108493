@import '../../../assets/scss/app';

.auto_complete_style {
  div > div {
    padding: 0 !important;
    border-radius: 0;

    input {
      padding: 10px !important;
      color: $neutrals-500;
      font-weight: 500;
      height: 38px;
      box-sizing: border-box;
    }

    fieldset {
      top: 0;
      border-color: $neutrals-300 !important;
      border-width: 1px !important;

      legend {
        display: none !important;
      }
    }
  }
}

ul > li {
  color: $neutrals-500;
  font-weight: 500;
}
