@import '../../assets/scss/app';

.notFoundCode {
  font-size: 100px;
  text-align: center;
  color: #03367e;
  font-weight: $bold;
}

.pageNotFound {
  font-size: 30px;
  font-weight: $bold;
  text-align: center;
  color: #03367e;
}

.content {
  text-align: center;
  font-size: 18px;
  margin-block: 15px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  .backButton {
    font-size: 16px;
    font-weight: $bold;
    background-color: unset;
    color: #03367e;
    border: 2px solid;
    padding: 10px 20px;
    transition: 0.1s linear;

    &:hover {
      border-color: #03c988;
      background-color: #03c988;
      color: $white;
    }
  }
}
