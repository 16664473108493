@import '../../assets/scss/app';

.container {
  position: relative;
}

.headerContainer {
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 3;
}

.bodyContainer {
  display: flex;
  padding: 15px;
  gap: 20px;

  .timeLineContainer {
    display: flex;
    flex-direction: column;
    flex-basis: 60%;

    .timeLineHeader {
      text-align: center;
    }

    .assetTimeLineParent {
      flex: 1;
      position: relative;
    }
  }

  .timeLineContainer::-webkit-scrollbar {
    display: none;
  }
}

.header {
  justify-content: space-between;

  .maintenanceRequestedContainer {
    display: flex;
    gap: 15px;

    .editBtn,
    .deleteBtn {
      padding: 0px 15px;
      svg {
        font-size: 20px;
      }
    }

    .editBtn {
      background-color: $secondary-500;
    }

    .deleteBtn {
      background-color: $danger;
    }

    .requested {
      display: flex;
      align-items: center;
      gap: 5px;
      background-color: $primary-2-300;
      color: $white;
      padding: 6px 10px;
      border-radius: 5px;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  .requestBtn {
    padding-inline: 20px !important;
    gap: unset;
    &:hover {
      span {
        scale: 1.06;
      }
    }
  }

  .header_name_container {
    display: flex;
    align-items: center;

    .cube_icon {
      color: $primary-2-600;
      font-size: 24px;
    }

    .header_name {
      white-space: nowrap;
      font-size: 20px;
      color: $neutrals-900;
      margin-left: 15px;
    }
  }

  .action_btns {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    flex-wrap: wrap;

    > button {
      border-radius: 5px;
    }

    .btn {
      display: flex;
      align-items: center;
      padding: 0px 5px;
      background-color: $primary-2-600;

      .moreIcon {
        transform: rotate(0deg);
        transition: transform 0.3s linear;
      }

      .rotate {
        transform: rotate(90deg);
      }
    }

    .deleteOptionStyle {
      color: $pale-red !important;
      background-color: $danger !important;
    }

    .editBtn {
      color: $secondary-700;
      background-color: unset;
      font-size: inherit;
      padding: 0px 10px;
      border: 1px solid;
    }

    .btn,
    .editBtn {
      &:hover {
        scale: 1.05;
      }
    }
  }
}

.actionMenu ul li {
  display: flex;
  gap: 10px;
  color: $neutrals-500 !important;
  font-weight: $medium !important;
  padding: 5px 15px;
  &:hover {
    background-color: $primary-50;
  }
}

.statusIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  > svg {
    max-width: 20px;
  }
}
.redHover ul li:nth-last-child(1) {
  color: $danger !important;
  &:hover {
    background-color: #ffe8e6;
  }
}

@media only screen and (max-width: $max-tablet-lg-width) {
  .action_btns {
    margin-left: 10px;
  }
}

@media only screen and (max-width: $min-tablet-lg-width) {
  .bodyContainer {
    flex-direction: column;
    gap: 30px;
  }
}

// Mobile Max Width

@media only screen and (max-width: $max-mobile-width) {
  .header {
    padding: 15px 14px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
  }

  .action_btns {
    flex: 1 1 60%;
  }
}

// Mobile Min Width

@media only screen and (max-width: $min-mobile-width) {
  .header {
    flex-direction: column !important;
    padding: 10px 20px;

    .header_name {
      font-size: 20px;
      color: $neutrals-900;
      margin-left: 15px;
    }
  }
}

//Filter

.searchBox {
  height: fit-content;
  display: none; // hided the filter for temporarily
}

.select {
  & fieldset {
    border: none;
    border-bottom: 1px solid black;
    border-color: black;
  }
}

.menuItem {
  padding-right: -80px !important;
  padding-left: -0px !important;
}

.input {
  > :first-child {
    height: 10px !important;
  }
}
.chipContainer {
  display: flex;
  align-items: center;
}

.chip {
  margin-inline-end: 5px;
  width: 100px;
}

@media only screen and (max-width: 800px) {
  .searchBox {
    align-self: center;
  }
}
