@import '../../assets/scss/app';

.drilldownPieChart {
  > div {
    margin-inline: auto;
    width: inherit !important;
    > svg {
      display: block;
      width: -webkit-fill-available;
    }
  }
  text {
    fill: $primary-900 !important;
    text-decoration: none !important;
  }
}

.add_menu li {
  > svg {
    max-width: 15px;
  }
  display: flex;
  gap: 10px;
  color: $neutrals-500 !important;
  font-weight: $medium !important;
  padding: 5px 15px;
}

.add_menu li:first-child:focus {
  background-color: unset !important;
}

.add_menu li:first-child:hover {
  background-color: $primary-50 !important;
}

.dropdown_container {
  display: flex;
  justify-content: flex-end;

  .add_btn {
    text-transform: capitalize !important;
    font-weight: $bold !important;
    background-color: $primary-600 !important;
    height: 35px;
    font-size: 15px;
    span {
      transition: scale 100ms ease;
    }
    svg {
      font-size: 21px;
    }
    span:nth-child(3) {
      svg {
        font-size: 25px;
        height: 25px;
      }
    }
  }
  .add_btn:hover {
    span {
      scale: 1.13;
    }
    span:nth-child(3) {
      svg {
        scale: 1.13;
      }
    }
  }
}
.viewAllButton:hover {
  svg {
    scale: 1.25;
  }
}
.viewAllButtonRoot {
  span,
  svg {
    font-weight: $bold;
  }
}

.paper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px;
  min-height: 100%;
  box-sizing: border-box;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }

  .details {
    .label {
      background-color: unset !important;
    }

    .value {
      font-weight: $bold;
      font-size: 20px;
      color: $neutrals-700;
    }
  }

  .icon_container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }

  .total_users {
    background-color: $primary-2-100;
    color: $primary-2-800;
  }

  .total_assets {
    background-color: $primary-50;
    color: $primary-800;
  }

  .available_assets {
    background-color: $secondary-100;
    color: $secondary-800;
  }

  .expiring_assets {
    background-color: $misty-rose;
    color: $pale-red;
  }
}

.charts_container {
  display: flex;
  gap: 30px;

  .pie_chart {
    box-sizing: border-box;
    min-height: -webkit-fill-available;

    div > div > svg > foreignObject > div {
      box-sizing: border-box;
      width: 120px;
      height: 100%;
    }
  }

  .semi_donut {
    .chart_wrapper {
      display: flex;
      justify-content: center;
    }

    div > div > svg > foreignObject > div {
      box-sizing: border-box;
      width: 100%;
      padding-inline: unset !important;
    }
  }
}

.chart_container {
  box-sizing: border-box;
  padding: 25px;
  padding-bottom: 0px;
  max-height: 350px;

  .chart_wrapper {
    div {
      margin: 0 auto;
      max-height: 280px !important;
      div > svg {
        max-height: 280px !important;
      }
    }
  }
}

.chart_header {
  font-weight: $bold;
  font-size: 18px;
  color: $neutrals-700;
}

.bar_chart_container {
  padding-top: 25px;
  padding-inline: 25px;
  margin-block: 20px;

  .bar_chart_header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;

    .filter_container {
      display: flex;
      gap: 20px;
      align-items: center;
      position: relative;

      .month_picker,
      .year_picker {
        position: absolute;
        right: 0;
        top: 30px;
        width: 300px;
        height: 300px;
        z-index: 1;
        background-color: $white !important;
        box-shadow: 0 0 10px $neutrals-300;
        border-radius: 5px;
        button {
          font-weight: $medium;
          color: $neutrals-500;

          &:global(.Mui-selected) {
            background-color: $primary-300;
            color: $white;
          }
          &:hover {
            background-color: $primary-50;
            color: $neutrals-500;
          }
        }
      }

      .year_picker > div > button {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .year_picker::-webkit-scrollbar {
        display: none;
      }

      span {
        font-weight: $bold;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .bar_chart_wrapper {
    div > div > svg > g {
      transition: none !important;
    }
  }
}

.customRangeInputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.to {
  padding: 10px;
}

.selectedOption {
  background-color: rgb(215, 215, 215);
  border-radius: 4px;
}

.dropdown {
  height: 38px;
  padding: 10px 14px;
  color: $neutrals-500;
  font-weight: $medium;
}

// Asset list
.assetListContainer {
  box-sizing: border-box;
  padding-inline: 25px;
  padding-bottom: 25px;

  .assetListHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 20px;

    .assetListViewAllBtn {
      font-size: inherit;
    }
  }
}

@media only screen and (max-width: $max-desktop-width) {
  .pie_chart {
    div > div > svg > foreignObject {
      height: 100% !important;
    }
    div > div > svg > foreignObject > div {
      width: 100% !important;
      height: 70px !important;
    }
  }
}

@media only screen and (max-width: $max-tablet-width) {
  .charts_container {
    flex-direction: column;
  }

  .chart_container {
    width: 100% !important;
  }

  .pie_chart {
    div > div > svg > foreignObject > div {
      width: 200px !important;
      height: 80% !important;
    }
  }
}

@media only screen and (max-width: $min-tablet-lg-width) {
  .pie_chart {
    max-height: 400px;

    div > div > svg > foreignObject > div {
      width: 100% !important;
      height: 70px !important;
    }
  }
}

@media only screen and (max-width: $max-mobile-width) {
  .chart_container {
    max-height: unset;

    .chart_wrapper {
      div {
        min-height: unset !important;
      }
    }
  }
  .dropdown_container {
    .add_btn {
      .add_btn_content {
        display: none;
      }
    }
  }
}
@media only screen and (max-width: $min-mobile-width) {
  .dropdown_container {
    & button {
      justify-content: space-between;
    }
  }
}
