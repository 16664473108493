@import '../../../assets/scss/_button.module.scss';
@import '../../../assets/scss/_app.module.scss';

.company_dropdown {
  width: 200px;
  border-radius: 10px;
}

.goBackBtn {
  height: 40px;
  margin-right: 5px !important;
}
.import_button{
  width: 210px;
}
.checkBox > svg {
  color: $primary-2-800;
  width: 21px;
}
.error_message {
  label {
    margin-left: 10px;
    font-size: 1.1rem;
  }
}
.success_message {
  background: $secondary-100;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 10px;
  label {
    margin-left: 10px;
    font-size: 1.1rem;
  }
  svg{
    color: $secondary-800!important;
  }
}
.upload_container {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
