@import '../../../assets/scss/app';

.avatarContainer {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;

  .avatar {
    height: 150px;
    border-radius: 50%;
  }

  .close {
    display: flex;
    color: $neutrals-400;
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      background-color: $neutrals-100;
    }
  }
}

.profileActions {
  display: flex;
  justify-content: space-evenly;
  margin-block: 10px;
  .edit,
  .reset {
    > svg {
      font-size: 1rem;
    }
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    border-radius: 5px;
  }
  .edit {
    color: $secondary-700;
    &:hover {
      background-color: $secondary-100;
    }
  }
  .reset {
    color: $primary-800;
    &:hover {
      background-color: $primary-50;
    }
  }
}

.detailHeader {
  font-size: 20px;
  margin-block: 10px;
}

.detailContainer {
  width: 90%;
  margin-inline: auto;

  .detail {
    display: flex;

    > span {
      word-break: break-all;
      margin-bottom: 10px;

      &:first-child {
        flex-basis: 40%;
        color: $neutrals-400;
        font-weight: $bold;
      }
      &:last-child {
        flex-basis: 60%;
        color: $neutrals-800;
      }
    }
  }
}

.textLink {
  color: $primary-800;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.noActivities {
  text-align: center;
  color: $neutrals-400;
}
