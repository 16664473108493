@import '../../../assets/scss/app';

.delete_button {
  background-color: $primary-500 !important;
  border-radius: 5px;
  color: $primary-50 !important;
  width: 69px;
  height: 23px;
  margin-top: 1px;
  font-size: 16px !important;
}
