@import '../../../assets/scss/app';

.container {
  flex-basis: 40%;
}

.assetImageContainer {
  display: flex;
  justify-content: center;
  border: 1px solid $neutrals-300;
  padding: 15px;
  margin-bottom: 20px;

  .imageContainer {
    display: flex;
    justify-content: center;
    height: 200px;
  }

  .emptyContainer {
    background-color: $neutrals-100;
  }

  .assetImage {
    margin: 10px;
    width: 100%;
  }
}

.noBottomPadding {
  padding-bottom: 0px;
}

.assetDetailRow {
  display: flex;
  width: 100%;
  .assetKey,
  .assetValue {
    display: flex;
    padding-block: 7px;
    font-size: 16px;
  }

  .assetKey {
    color: #98a2b3;
    font-weight: $semibold !important;
    font-size: $font-size-xl !important;
    padding-inline: 20px;
    flex-basis: 50%;
  }

  .assetValue {
    width: 75%;
    color: $neutrals-800;
  }
}
