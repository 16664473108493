@import '../../assets/scss/app';

.pageNamestyle {
  display: flex;
  align-items: center;
}
.header_container {
  justify-content: space-between;
}
.rightSubHeader {
  display: flex;
  align-items: center;
  gap: 15px;
}
.searchParentContainer {
  padding-left: $spacing-05;
}

.body_container {
  height: 100%;
  overflow: auto;
  padding-inline: 20px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.suffix_icon {
  z-index: 0;
  display: block;
  box-sizing: border-box;
  border: none;
  border-radius: 5px;
  outline: none;
  color: $neutrals-400;
  position: relative;
  top: 5px;
  left: 5px;
}
.search_icon {
  padding: 0 !important;
}

.flex_align_center {
  display: flex;
  align-items: center;
  gap: 15px;
}

.filterSelectContainer {
  flex-wrap: wrap;
  justify-content: flex-end;
}

.filter_container {
  gap: 15px;
  display: flex;
  align-items: center;
}

.filter_icon_container {
  background-color: $primary-2-300;
  align-items: center;
  justify-content: center !important;
  display: flex;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

div.filter_icon_container {
  svg {
    transition: scale 100ms ease;
  }
}
div.filter_icon_container:hover {
  svg {
    scale: 1.14;
  }
}

.solid_list_icon {
  color: $white !important;
}

.search_field {
  border: 1px solid $neutrals-300;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  height: 35px;
  min-width: 103px;

  .searchInput {
    border: none;
    outline: none;
    outline-width: 0;
    width: 100%;
  }
  .search_icon {
    color: $neutrals-400;
    cursor: pointer;
    font-size: $font-size-xl !important;
  }
}

.search_field:hover {
  border-color: $neutrals-500;

  .search_icon {
    color: $neutrals-500;
  }
}

.search_field:focus-within {
  border-color: $neutrals-500;

  .search_icon {
    color: $neutrals-500;
  }
}

.user_search_field {
  width: 100%;
}

.input_field {
  border: none;
  display: flex;
  align-items: center;
}

.label_displayed_rows {
  display: flex;
  align-items: right;
  margin-left: auto;
}

.subheader_container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
  > div {
    display: flex;
    gap: 12px;
    justify-content: space-between;
    width: 100%;
  }
  .filtersContainer {
    width: 100%;
    justify-content: space-between;
  }
}
.switch_container {
  display: flex;
  margin-left: 10px;
  align-items: center;
  label {
    margin-left: 5px;
    font-size: 0.8rem;
  }
}

.other_branches_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addButton {
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  outline: none;
  font-weight: $bold;
  background-color: $primary-600;
  border-radius: 5px;
  color: $white;
  font-size: $font-size-lg;
}

.filter_modal_badge > span {
  background-color: $primary-600 !important;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  top: 3px;
  right: 3px;
}

.search_cancel_button {
  background-color: $white;
  color: $neutrals-400;
  cursor: pointer;
  font-size: $font-size-xl !important;
}

.add_menu ul {
  padding-inline: 0px;
  padding-block: 4px;
}

.add_menu li {
  > svg {
    max-width: 15px;
  }
  display: flex;
  gap: 10px;
  color: $neutrals-500 !important;
  font-weight: $medium !important;
  padding: 5px 15px;
}
.add_menu li:first-child:focus {
  background-color: unset !important;
}

.add_menu li:first-child:hover {
  background-color: $primary-50 !important;
}

.dropdown_container {
  display: flex;
  justify-content: flex-end;
  gap: 25px;

  .add_btn {
    text-transform: capitalize !important;
    font-weight: $bold !important;
    background-color: $primary-600 !important;
    min-width: 50px;
    height: 35px;
    font-size: 15px;
    span {
      transition: scale 100ms ease 0s;
    }
    span:first-child {
      margin-right: 8px;
      margin-left: -4px;
    }
    svg {
      font-size: 21px;
    }
    span:nth-child(3) {
      svg {
        font-size: 25px;
        height: 25px;
      }
    }
  }
  .add_btn:hover {
    span {
      scale: 1.15;
    }
    span:nth-child(3) {
      svg {
        scale: 1.13;
      }
    }
  }
}

.tabHeader {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid $neutrals-300;
  overflow-y: hidden;
  overflow-x: scroll;

  .selectedTab {
    opacity: 1;
    border-bottom: 3px solid $primary-2-600;
    position: relative;
    font-weight: 500;
  }
  & > span {
    opacity: 0.5;
    cursor: pointer;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 8px;
  }
  .tab {
    display: flex;
    gap: 10px;
  }
}

.tabHeader::-webkit-scrollbar {
  display: none;
}

.title_container {
  display: flex;
}

@media only screen and (max-width: $max-tablet-lg-width) {
  .searchParentContainer {
    padding-left: 12px;
  }
  .details_container {
    flex-direction: column;
  }
  .rightSubHeader {
    gap: 11px;
  }
  .feedback_header_container {
    gap: unset;
  }
  .feedback_title_container {
    width: 100%;
  }
  .feedback_filter_select_container {
    justify-content: end !important;
  }
}

@media screen and (max-width: $min-tablet-lg-width) {
  .feedbackSearchParentCon {
    padding-left: 0px;
    width: 100%;
  }
  .header_container {
    flex-direction: column;
    gap: 15px;
  }
  .title_container {
    width: 100%;
    justify-content: space-between;
    gap: 12px;
  }
  .feedback_title_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
  }

  .filterSelectContainer {
    justify-content: start;
  }

  .flex_align_center {
    width: 100%;
  }
  .rightSubHeader {
    justify-content: flex-end;
  }
}

@media only screen and (max-width: $max-mobile-width) {
  .searchParentContainer {
    padding-left: 0px;
  }
  .suffix_icon {
    left: unset;
    right: 320px;
  }

  .subheader_container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
  }

  .dropdown_container {
    .add_btn {
      .add_btn_content {
        display: none;
      }
    }

    .addbuttonTypeContainer {
      span:first-child {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
}

@media screen and (max-width: $min-mobile-width) {
  .title_container {
    flex-direction: column !important;
  }
  .switch_container {
    margin-left: unset;
  }
  .rightSubHeader {
    gap: unset;
    align-items: flex-end;
    // padding-bottom: 3px;
  }
  .feedbackRightSubHeader {
    width: unset;
  }
  .other_branches_header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .header_container {
    flex-direction: row;
    gap: 5px;
  }
  .userHeader_container {
    flex-direction: column;
  }
  .flex_align_center {
    width: 100%;
    align-self: flex-start;
    justify-content: flex-start;
  }

  .subheader_container {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    > div {
      justify-content: unset;
    }
  }
  .feedback_subheader_container {
    > div {
      // justify-content: space-between !important;
      flex-direction: column !important;
    }
  }
  .filter_container {
    .search_field {
      width: -webkit-fill-available;
    }
  }
  .dropdown_container {
    flex-direction: column;
    .company_dropdown {
      max-width: 100%;
    }
    & button {
      justify-content: space-between;
    }
  }
  .search_field {
    min-width: 90px;
  }
  .userPageBtn {
    > div {
      button {
        padding-inline: revert !important;
      }
    }
  }
}
