@import '../../assets/scss/app';

.prefix_icon_style {
  color: $primary-600;
}
.TextField_style {
  width: 100%;
}
.description_style {
  padding-right: 58px;
}

.title_name_icon {
  display: none;
  content: '';
}

.form_sec_sub_container {
  display: flex;
  justify-content: center;
  white-space: nowrap;
}
.form_sub_cont {
  width: 100%;
}

.button_upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 169px;
  min-width: 25vw;
  border: 2px dashed $neutrals-300;
  background-color: transparent;
}
.text_drop_image {
  color: #50648a;
  font-weight: 400;
  font-size: 14px;
  padding-top: 9px;
}

.text_image_type {
  color: $neutrals-300;
  font-weight: 500;
  font-size: 11px;
  padding-block-start: 10px;
  white-space: break-spaces;
}

.text_drop_file {
  color: #50648a;
  font-weight: 400;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 0px;
}
.text_file_type {
  color: $neutrals-300;
  font-weight: 500;
  font-size: 11px;
  margin: 5px;
}
.default_upload_image {
  height: 93px;
}

.button {
  background-color: $primary-2-600 !important;
  font-size: 18px !important;
  text-transform: capitalize !important;
  font-weight: 700 !important;
  height: 40px;
  width: 120px;
  box-shadow: none !important;
}
.button_next {
  background-color: $primary-2-600 !important;
}

.profile_icon {
  color: $orange;
  width: 42px !important;
  height: 37px !important;
}

.textField_prefix {
  width: 100%;
}

.mailIcon {
  color: $primary-600;
}
.default_upload_user_image {
  width: 100%;
  max-height: 150px;
}

.grid_text_field_container {
  max-width: 100% !important;
}

.dropdown_style {
  width: 40vw;
  color: $neutrals-300;
  font-weight: 500;
  font-size: 16px;
}

.image_container {
  position: relative;
  width: 150px;
}

.remove_btn_container {
  position: absolute;
  top: -8px;
  right: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: $neutrals-500;
  color: $white;
}

.remove_btn {
  cursor: pointer;
}

// custom country city dropdown css overide

.icon_remove {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-image: url('../images/Drop_down_box_downarrow.svg');
  background-size: 0.6em;
  background-position: calc(100% - 1.3em);
  color: $neutrals-500 !important;
}

.custom_dropdown_placeholder {
  color: $neutrals-400 !important;
}

@media only screen and (min-width: $min-tablet-width) and (max-width: $max-tablet-width) {
  .dropdown_style {
    width: 50vw;
  }

  .button_upload {
    width: 45vw;
  }
}

@media only screen and (max-width: $max-mobile-width) {
  .dropdown_style {
    width: 75vw;
  }
  .default_upload_image {
    height: 72px;
    width: 64px;
  }
  .buttons_container {
    display: flex;
    justify-content: center;
    padding-top: 15px;
  }
  .button_upload {
    width: 57vw;
  }

  .button {
    font-size: 16px !important;
  }
}
