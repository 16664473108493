@import '../../../assets/scss/app';

.date_picker {
  button {
    padding: 0;
  }
}

.text_field {
  width: 100%;
  height: 38px;
  div {
    padding-left: 0px !important;

    fieldset {
      border: 0.5px solid $neutrals-500 !important;
    }

    & :hover {
      border-color: none !important;
    }

    input {
      padding-inline: 10px !important;
      color: $neutrals-500;
      font-weight: 500;
    }
  }
}

.Input_adornment {
  cursor: pointer;
  margin-right: 0px !important;
  border-radius: 0px 1px 1px 0px;
  background-color: #eaecf0;
  color: $primary-600 !important;
  width: 52px;
  height: 38px !important;
  max-height: 56px !important;
  justify-content: center;
}
