@import '../../assets/scss/app';
.filtersContainer {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: flex-end;
}

.header {
  gap: 20px;
  flex-direction: row;
  justify-content: space-between;

  .title {
    display: flex;
    align-items: center;
    padding-left: 0;
  }
}

.tabHeader {
  display: flex;
  align-items: center;
  margin-inline: 25px;
  border-bottom: 1px solid $neutrals-300;
  overflow-x: scroll;

  .selectedTab {
    opacity: 1;
    border-bottom: 3px solid $primary-2-600;
    position: relative;
    font-weight: $medium;
  }

  span {
    opacity: 0.5;
    cursor: pointer;
    padding-block: 20px;
    padding-inline: 30px;
  }
}

.tabHeader::-webkit-scrollbar {
  display: none;
}

.tabContainer {
  height: 100%;
  overflow: auto;
  padding: 0px 20px 10px 20px;
  flex: 1;
}

.asset_photo {
  max-height: 50px;
  max-width: 70px;
}

.action_btns {
  display: flex;
  gap: 20px;

  .more_action {
    width: 160px;

    span,
    svg {
      transition: scale 100ms ease;
    }
    span:nth-child(1) {
      margin-right: 5px;
    }

    div > div > div {
      background-color: $secondary-500;
      color: $white;
      border-radius: 5px;
      border: none;
      height: 100%;
      min-height: 21px;
      font-weight: 700;
    }

    div > div > svg {
      color: $white;
    }
  }
}
.action_btns:hover {
  span {
    scale: 1.04;
  }
  .more_action > svg {
    scale: 1.18;
  }
  div.more_action > svg {
    scale: 1.3;
  }
}
.hoverEffectRoot {
  gap: 5px;
  span {
    font-weight: 700;
    font-size: 0.9rem;
    white-space: nowrap;
  }
  svg {
    font-size: 1.2rem;
  }
}
.hoverEffectRoot:hover {
  span {
    scale: 1.07;
  }
}

@media only screen and (max-width: $min-tablet-width) {
  .header {
    flex-direction: column;
  }
}

@media only screen and (max-width: $min-mobile-width) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .action_btns {
    width: 100%;
    .more_action {
      width: 100%;
    }
  }

  .filtersContainer {
    width: 100%;
    flex-direction: column;
    button {
      width: 100%;
    }
  }
}
