@import './color';

// font-weight

$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

// font-size

$font-size-sm: 0.75em; //  12px
$font-size-md: 0.875em; //  14px
$font-size-lg: 1em; //  16px
$font-size-xl: 1.125em; // 18px
$font-size-2xl: 1.25em; // 20px
$font-size-3xl: 1.5em; // 24px
$font-size-4xl: 1.875em; // 30px

.regular {
  font-weight: $regular;
}

.medium {
  font-weight: $medium;
}

.semibold {
  font-weight: $semibold;
}

.bold {
  font-weight: $bold;
}

.display_text {
  color: $neutrals-900;
  flex: none;
  flex-grow: 1;
}

.text {
  width: 536px;
}

.display_2xl {
  font-size: 4.5em;
}

.display_xl {
  font-size: 3.75em;
}

.display_lg {
  font-size: 3em;
}

.text_4xl {
  font-size: 2.125em;
}

.text_3xl {
  font-size: $font-size-4xl;
}

.text_2xl {
  font-size: $font-size-3xl;
}

.text_xl {
  font-size: $font-size-2xl;
}

.text_lg {
  font-size: $font-size-xl;
}

.text_md {
  font-size: $font-size-lg;
}

.text_sm {
  font-size: $font-size-md;
}

.text_sx {
  font-size: $font-size-sm;
}

@media only screen and (max-width: 1600px) {
  .text_4xl {
    font-size: $font-size-4xl;
  }
  .text_3xl {
    font-size: $font-size-3xl;
  }

  .text_2xl {
    font-size: $font-size-2xl;
  }

  .text_xl {
    font-size: $font-size-xl;
  }

  .text_lg {
    font-size: $font-size-lg;
  }

  .text_md {
    font-size: $font-size-md;
  }
  .text_sm {
    font-size: $font-size-sm;
  }
}

@media only screen and (max-width: 1024px) {
  .text_3xl {
    font-size: $font-size-2xl;
  }
}

@media only screen and (max-width: 600px) {
  .text_3xl {
    font-size: $font-size-xl;
  }
}
