@import '../../assets/scss/app';

.attachment_container {
  .row {
    display: flex;
    margin-bottom: 10px;
  }

  a:link {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  a:active {
    text-decoration: none;
  }

  .thumbnail_container {
    background: $neutrals-100;
    width: 169px;
    height: 113px;

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      height: 61px;
      width: 43px;
      padding-top: 20px;
    }
  }

  .header_name {
    font-size: 20px;
    color: $neutrals-900;
    font-weight: 600;
  }

  .attachment_details {
    margin-left: 30px;
    margin-top: 15px;
    color: $neutrals-800;

    .attachment_uploaded_details {
      color: $neutrals-500;
      font-size: 0.8rem;
    }
  }
}
