@import '../../../assets/scss/app';

.loginButton {
  background: #023b96 !important;
  width: 14vw;
  font-size: 15px;
  font-weight: 700;
}

.filter_container {
  display: flex;
  gap: 10px;
}
.header_container {
  position: sticky;
  top: 0;
  z-index: 2;
}

.app_logo {
  width: 174px;
  display: flex;
  align-self: center;
  img {
    height: 30px;
  }
}
.curser {
  cursor: pointer;
}

.notificationBell {
  position: relative;
  cursor: pointer;
  top: 3px;
}
.notificationBell:hover {
  svg {
    color: $primary-2-300 !important;
  }
  .notification_badge_inactive {
    background-color: $primary-800;
  }
}
.notification_badge {
  display: flex;
  align-items: center;
  position: absolute;
  top: -11px;
  left: 13px;
  padding-inline: 6px;
  border-radius: 30%;
  background-color: $primary-800;
  color: $white;
  font-size: 0.8rem;
  height: 20px;
  min-width: 18px;
}
.notification_badge_inactive {
  background-color: $primary-2-800;
}

.menu_item {
  color: $neutrals-400;
  display: flex;
  justify-content: space-between;
}
.menu {
  width: 200px !important;
}

.menu li img {
  color: $neutrals-400;
}

.menu li {
  justify-content: flex-start;
  font-style: normal;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: $neutrals-600;
  background-color: $white;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  gap: 11px;
  padding-block: 10px !important;
  &:hover {
    background-color: $primary-50 !important;
  }
}

.menu li:focus {
  background-color: unset !important;
}
.menu li:nth-child(1) {
  cursor: default;
  &:hover {
    background-color: none !important;
  }
}
.menu ul li:nth-last-child(1) {
  color: $danger !important;
  &:hover {
    background-color: #ffe8e6 !important;
  }
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;
  height: max-content;
  background-color: $white;
  padding-inline: 25px;
}
.sub_header_con {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.side_menu_btn_container {
  display: flex;
  align-items: center;
  align-self: center;
  gap: 24px;
}

.side_menu_btn {
  display: none;
  margin-right: 10px;
  cursor: pointer;
}
.show_side_menu_btn {
  display: flex;
  margin-right: 10px;
  cursor: pointer;
}

.search_icon {
  display: block;
  color: $neutrals-400;
}

.search_field_container {
  z-index: 1;
}

.profile_pic_container {
  height: 45px;
  width: 45px;
  > img {
    width: 100%;
  }
}

.profile_pic {
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

.profile_info {
  justify-content: center;
  display: flex;
  flex-direction: column;
  max-width: 269px;
}
.profile_name,
.profile_role {
  color: $neutrals-900;
}

.profile_name {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.profile_role {
  font-weight: $regular;
}

.profile_info_arrow {
  display: flex;
  align-items: center;
  color: $neutrals-300;
}
.dropdown_container {
  background-color: aqua;
  display: contents !important;
}
.drop_down_box {
  margin-left: 15px;
  display: flex;
  cursor: pointer;
  align-items: center;
}
.header_right {
  gap: 15px;
  display: flex;
  align-items: center;
}
.ul {
  margin: 10px 0 0 0 !important;
}

.company_icon {
  object-fit: cover;
  box-shadow: black 0px 0px 1px 0px;
  cursor: pointer;
}

.mob_filter_container {
  display: none;
}

.profile_details {
  display: flex;
  gap: 14px;
}
.iconCon {
  height: 15px;
  width: 15px;
}

@media only screen and (max-width: $max-desktop-lg-width) {
  .header {
    min-height: 75px;
    height: max-content;
  }

  .profile_pic_container {
    height: 36px;
    width: 36px;
  }

  .show_side_menu_btn {
    margin-left: -30px;
  }
}

@media only screen and (max-width: $min-tablet-lg-width) {
  .loginButton {
    width: 21vw;
  }

  .filter_container {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
}

@media only screen and (max-width: $max-tablet-width) {
  .header {
    height: 69px;
    padding-inline: 15px;
  }

  .show_side_menu_btn {
    margin-left: 0px;
  }

  .profile_pic {
    margin-left: 0px;
  }

  .profile_info_arrow {
    margin-inline: 5px;
  }
}

@media only screen and (max-width: $max-mobile-width) {

  .notificationBell {
    top: 5px;
  }
  .loginButton {
    width: 31vw;
  }
  .header_right {
    gap: unset;
  }
  .side_menu_btn {
    display: flex;
  }

  .profile_info_arrow {
    display: none;
  }
  .show_side_menu_btn {
    margin-left: 0px;
  }
  .drop_down_box {
    margin-left: 30px;
  }
  .hide_company_filter {
    display: none !important;
  }
  .mobile_filter {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .filter_container {
    display: none;
  }
  .mob_header {
    height: 90px;
    padding: 10px;
    flex-direction: column;
  }
  .mob_filter_container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    > div {
      width: 45%;
      > div {
        width: 100%;
      }
      > div:nth-child(1) {
        justify-content: start;
      }
    }

    > div:nth-child(2) {
      > div {
        justify-content: end;
      }
    }
  }
  .app_logo {
    width: 150px;
  }

  .side_menu_btn_container {
    gap: 8px;
  }
}

@media only screen and (max-width: $min-mobile-width) {
  .mob_header_container {
    display: flex;
    flex-direction: column;
  }
}
